import React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack';
import './Heading.scss'

const Heading = () => {
    return (
        <Box className="heading">
            <Container className="heading-container">
                <Grid container>

                    <Grid item xs={12} sm={8} md={9} lg={10}>
                        <Stack>
                            <div className="heading-title">
                               {process.env.REACT_APP_BANNER_TITLE}
                            </div>
                            <div className="heading-subtitle">
                                {process.env.REACT_APP_BANNER_SUBTITLE}
                            </div>
                            <div className="heading-body">
                                {process.env.REACT_APP_BANNER_DESCRIPTION}
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2} className="heading-contact">
                        <Button variant="contained" className="btn" href="mailto:OrigoTechPartners@hidglobal.com">
                            Contact Us
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Heading;