import { call, put, select } from "redux-saga/effects"
import { cartSagaActionTypes, fetchCartSagaActions } from "../saga-actions"
// import { cartSliceAction } from '../../../slices/cartItems'
import {cartSliceAction} from '../../../../redux/slices/cart'
import { axiosGraphqlInstance } from "../../../../common/axios"
import { AxiosResponse } from "axios"

type deleteCartItemResponseType = {
    data: {
    }
}

const temp = ["${data.mainProductURl}", 
"${data.objectUrl[0]}",  
"${data.objectUrl[1]}", 
"${data.objectUrl[2]}", 
"${data.objectUrl[3]}"]
 
const deleteItemsFromCartAPI = async (payload:{
      cardId : string,
      productCartLineId : string,
      attributesIdsString : string,
}) => {
    let response = await axiosGraphqlInstance.post('/graphql.json',
        {
            query: `mutation { 
                cartLinesRemove(cartId: "gid://shopify/Cart/${payload?.cardId}", 
                  lineIds: ["${payload?.productCartLineId}",
                  ${payload?.attributesIdsString} 
                  ]) { 
                  cart { 
                    id 
                    checkoutUrl 
                    lines(first: 100) { 
                      edges { 
                        node { 
                          id 
                          quantity 
                          attributes { 
                            key 
                            value 
                          } 
                          merchandise { 
                            ... on ProductVariant { 
                              id 
                              product { 
                                id 
                                title 
                                productType 
                                metafield(namespace: "custom", key: "validation_date") { 
                                  value 
                                } 
                                featuredImage { 
                                  url 
                                } 
                              } 
                            } 
                          } 
                        } 
                      } 
                    } 
                    buyerIdentity { 
                      customer { 
                        id 
                        email 
                        phone 
                      } 
                    } 
                  } 
                  userErrors { 
                    field 
                    message 
                  } 
                } 
              }`,
           
            variables : {}
        }
    )
        .then((response) => response)
        .catch((error) => error)
    return response.data
}

function* deleteItemsFromCart(actions: {
    type: any,
    payload: {
      data : {
        attributesIdArray: [],
        productCartLineId: string,
        productVariantId: string,
    }
    }
}) {
    try {
     const cardId : string | null = localStorage.getItem('cartId');
    //  let lineIdsArray = [actions?.payload?.data?.productCartLineId,
    //     ...actions?.payload?.data?.attributesIdArray
    //  ]
    // let lineIds = `[${lineIdsArray.join(',')}]`
    let payload = {
      cardId : cardId,
      productCartLineId : actions?.payload?.data?.productCartLineId,
      attributesIdsString : actions?.payload?.data?.attributesIdArray?.map((e) => `"${e}"`).join(","),
    }
    console.log("payload", payload)
     const response: deleteCartItemResponseType = yield call(deleteItemsFromCartAPI, payload);
        yield put(fetchCartSagaActions(cardId))
            yield put(cartSliceAction.errorInfoTrigger({
                severity: "error",
                errorMsg: "Invalid userID or password!",
                open: true
            }))
        
     } catch (e) {
        console.error('====>error from axios', e)
        yield put(cartSliceAction.errorInfoTrigger({
            severity: "error",
            errorMsg: "Technical Error!",
            open: true
        }))
    }
}


export default deleteItemsFromCart;
