import React, { MutableRefObject, useState } from 'react'
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { CircularProgress, Divider } from '@mui/material';
import { Skeleton } from '@mui/material';
import Button from '@hid-galaxy-ui/galaxy-react/components/Button';
import Iconography from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import Carousel from "../../common/components/Carousel/Carousel"
import { useLocation } from 'react-router';
import { getProductDetailAction } from "../../redux/saga/product-detail/saga-action"
import { useAppDispatch, useAppSelector } from '../../redux';
import { useNavigate } from "react-router-dom";
import './product-detail.scss';
import SkeletonOnLoadingPDP from "./skeleton";
import ProductCard from '../../common/components/ProductCard/ProductCard';
import { getCartSagaActions } from '../../redux/saga/cartItem/saga-actions';
import RecommendedProductCard from '../../common/components/recommended-product-card';
import { cartCreateOrUpdateCartSagaActions, fetchCartSagaActions } from '../../redux/saga/cart/saga-actions';
import { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover} from '../../common/components/CustomPopover';
import convertToTwoDecimalValue from '../../common/utils/convertToTwoDecimalValue';

const ProductsDetailsPage: React.FC<{}> = (props) => {
    const { collection, status } = useAppSelector((state) => state.pdp);
    const navigate = useNavigate();
    const location = useLocation();
    const stateByLocation = location.state?.productID;
    const plpRoute = location.state?.plpRoute;
    const dispatch = useAppDispatch();
    console.log("location.state --> product-detail --> plpRoute", plpRoute);
    const { cartItems, cartSize, isLoading } = useAppSelector((state) => state.cart);
    // console.table(cartItems?.lines?.edges[0].node?.attributes)
    const cardId: string | null = localStorage.getItem('cartId');
    // console.log("collection in ---> PDP", cartSize);
    const [anchorElForPopover, setAnchorElForPopover] = useState(null);

    React.useEffect(() => {
        if (cardId) {
            dispatch(fetchCartSagaActions(cardId))
        }
    }, []);
    // h5 mouse hover
    const textForH5: string = collection?.productDetail?.title?.slice(0, 16)?.concat('...')
    const h5BreadCrumRef = React.useRef<HTMLHeadingElement>();
    const h5BreadCrumMouseEventHandle: (event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void = (event) => {
        if (event.type === 'mouseenter') {
            h5BreadCrumRef.current.textContent = collection?.productDetail?.title
        } else {
            h5BreadCrumRef.current.textContent = textForH5
        }
    }
    ///........................

    React.useInsertionEffect(() => {
        window.scrollTo({
            top: 0
        })
        dispatch(getProductDetailAction(location.state?.productID))
    }, [stateByLocation]) // willUpdate when productID changes
    let productVariantId: string = collection?.productDetail?.variants?.edges[0]?.node?.id;
    const featureSectionTitleAndIdArray: { variantId: string, title: string }[] = collection?.featureSection?.map((element, index) => {
        return {
            variantId: element?.variants?.edges[0]?.node?.id,
            title: element?.title
        }
    })
    let cartId: string | null = localStorage.getItem('cartId');
    let userToken: string | null = localStorage.getItem('user-token');
    console.log("productVariantId, featureSectionTitleAndIdArray", productVariantId, featureSectionTitleAndIdArray)
    const addToCartOnClick: () => void = () => {
        dispatch(cartCreateOrUpdateCartSagaActions({
            cartId: cartId,
            userToken: userToken,
            pdp: {
                featureSectionTitleAndVariantIdArray: featureSectionTitleAndIdArray,
                productVariantId: productVariantId
            },
            navigateFunction: () => { navigate('/cart') }
        }))
        // navigate('/cart')
    }
    const cardsForCarousel = (loading: boolean) => {
        if (loading) {
            return [1, 2, 3, 4].map(() => (<Skeleton style={{
                borderRadius: "10px",
                height: "330px",
                width: "185px",
            }} variant='rectangular' />))
        } else {
            return collection.recommendedSection?.map((element: any, index : number) => (
                <RecommendedProductCard product={element} />
            ))
        }
    }

    return (<>
        <div className="product-details-container">
            <Stack spacing={2} className="nav-info">
                <Breadcrumbs separator={<Iconography icon="angleRight" />} >
                    {[
                        <h6>Home</h6>,
                        <h6>Products</h6>,
                        <h6>{plpRoute === undefined ? 'Featured Packages' : plpRoute}</h6>,
                        status?.loading ?
                            <></> :
                            <h5 
                                // ref={h5BreadCrumRef}
                                // onMouseEnter={h5BreadCrumMouseEventHandle}
                                // onMouseLeave={h5BreadCrumMouseEventHandle}
                            >
                                {/* {textForH5} */}
                                {collection?.productDetail?.title}
                            </h5>
                    ]}
                </Breadcrumbs>
            </Stack>

            {status?.loading ? <SkeletonOnLoadingPDP /> : <><div className="product-info">
                <Grid container>
                    <Grid item md={4}>
                        <div className="img-wrapper">
                            <img src={collection?.productDetail?.featuredImage?.url} />
                        </div>

                    </Grid>
                    <Grid item md={8}>
                        <div className="text-wrapper">
                            <div className="title">{collection?.productDetail?.title}</div>
                            <div className="price-text">
                                {/* Price Per Year &nbsp; */}
                                <div className="price">${convertToTwoDecimalValue(collection?.productDetail?.priceRange?.maxVariantPrice?.amount)}</div>
                            </div>
                            <div className="content">
                                {collection?.productDetail?.description}
                            </div>
                            <div className='btn-div'>
                                {/* <Button variant='primary' normalCase={true} label='Buy Now' className="buy-btn"
                                    aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"    //! please go to css when you uncomment this
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose} /> */}
                                {cartSize === 0 || cartSize === undefined ? <button className="cart-btn"
                                    onClick={addToCartOnClick}
                                >
                                    Add To Cart&nbsp;{isLoading ? <CircularProgress color="inherit" size={24} /> : <></>}
                                </button> : <button className="cart-btn"
                                    onClick={() => { navigate("/cart") }}
                                >
                                    Go To Cart
                                </button>}

                                {/* <button className="cart-btn"
                                    onClick={addToCartOnClick}
                                >
                                   Add To Cart&nbsp;{isLoading ? <CircularProgress color="inherit" size={24} /> : <></>}
                                </button> */}

                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

                {collection?.featureSection?.length === 0 ? <></> : <><div className="product-features">
                    <div className="tabs">
                        <div className={true ? "active" : "inactive"}>
                            Features
                        </div>
                        <Divider flexItem={true} orientation={'vertical'} />
                        <div className={false ? "active" : "inactive"}>
                            Case Studies
                        </div>
                    </div>

                    <Grid container>
                        {collection?.featureSection?.map((parentElement, parentIndex) => {
                            return (<Grid item md={6}>
                                <div className="feature-grid-item">
                                    <div className="icon-wrapper">
                                        <Iconography icon="page" style={{ transform: 'scale(5)' }} />
                                    </div>
                                    <div className="feature-list">
                                        {parentElement?.title}
                                        {parentElement?.description.split("\\").map((element, index) => {
                                            return (<div className="list-item"><Iconography icon="circleCheck" />&nbsp; {element}</div>)
                                        })}

                                    </div>
                                </div>
                            </Grid>)
                        })}
                    </Grid>

                </div></>}

                {collection?.recommendedSection?.length === 0 ? <></> : <><div className="addons-title">
                    Recommended Addons
                </div>
                    <div style={{ paddingBottom: "1rem" }}>
                        <Carousel products={cardsForCarousel(status.loading)} />
                    </div></>}

            </>}
            <UiPopover anchorElForPopover={anchorElForPopover} setAnchorElForPopover={setAnchorElForPopover}/>
        </div>
    </>)

}

export default React.memo(ProductsDetailsPage);
