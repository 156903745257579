import React from 'react';
import { Divider } from '@mui/material';
import Card from "@hid-galaxy-ui/galaxy-react/components/Card";
import Iconography from "@hid-galaxy-ui/galaxy-react/components/Iconography"
import moment from 'moment';
import { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover } from '../CustomPopover';
import "@hid-galaxy-ui/galaxy-css/galaxy.css";
import "./ServiceContractCard.scss";

const ServiceContractCard: React.FC<{
    cardDetails: {
        Active: string
        ContractEndDate: string
        ContractId: string
        ContractName: string
        ContractStartDate: string
        Created: string
        LastUpdated: string
        OnboardingStageId: string
        PartnerId: string
        PartnerManagerId: string
    },
}> = ({ cardDetails }) => {
    return (
        <div className="service-card-container">
            <Card >
                <>
                    <div className="head" style={moment(cardDetails.ContractEndDate) > moment() ? { background: '#A2C871' } : { background: '#C87181' }}></div>

                    <div className="title">HID</div>
                    <div className="name">{cardDetails.ContractName}</div>
                    <div className="card-bottom">
                        <div className="date-box">
                            <div className="ordered">
                            Purchased:&nbsp;<div className="date">{moment(cardDetails.ContractStartDate).format('DD-MM-YYYY')}</div>  {/*1-Jan-2023*/}
                            </div>
                            <div className="validity">
                                Validity:&nbsp;<div className="date">{moment(cardDetails.ContractEndDate).format('DD-MM-YYYY')}</div>
                            </div>
                        </div>

                        <div className="details" onClick={() => console.log()}>
                            <div
                                className='details-text'
                                aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}>
                                    Details&nbsp;<Iconography icon="angleRight"/>
                            </div>
                        </div>

                    </div>
                </>
            </Card>
        </div>
    )
}

export default ServiceContractCard