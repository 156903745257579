import React, { useState } from "react";
import Heading from "../../common/components/Heading/Heading";
import ImageCarousel from "../../common/components/ImageCarousel/ImageCarousel";
import ServiceContractCard from "../../common/components/ServiceContractCard/ServiceContractCard";
import ProductCard from "../../common/components/ProductCard/ProductCard";
import Iconography from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import { getFeaturedProductSagaAction, getPromotionalBanners } from "../../redux/saga/home/saga-actions"
import { contractFindAllSagaAction } from "../../redux/saga/my-account/saga-actions"
import { useAppDispatch, useAppSelector } from "../../redux";
import "./Homepage.scss"
import { useNavigate } from "react-router";
import { Divider } from "@mui/material";
import { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover } from '../../common/components/CustomPopover';

function HomePage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { featuredCollection, banners } = useAppSelector((state) => state.home);
    const { contracts } = useAppSelector((state) => state.my_account);
    console.log("homepage --> banners", banners)
    console.log("fraturedCollection", featuredCollection)
    const memoisedBanners = React.useMemo(() => banners, [banners])
    const [serviceSeeMore, setServiceSeeMore] = useState(false);
    const [packageSeeMore, setPackageSeeMore] = React.useState<{
        hidden: boolean,
        state: boolean
    }>({
        hidden: false,
        state: false
    });

    const [anchorElForPopover, setAnchorElForPopover] = React.useState<HTMLElement | null>(null);
    
    const handleServiceSeeClick = () => {
        setServiceSeeMore(!serviceSeeMore);
    }

    const handlePackageSeeClick = () => {
        setPackageSeeMore({ ...packageSeeMore, state: !packageSeeMore.state });
    }

    React.useInsertionEffect(() => {
        if (featuredCollection.length === 0) {
            dispatch(getFeaturedProductSagaAction());
        }
        dispatch(getPromotionalBanners());
        dispatch(contractFindAllSagaAction());
    }, [featuredCollection])

    const apiData = {
        "data": {
            "articles": {
                "nodes": [
                    {
                        "id": "gid://shopify/Article/604529164565",
                        "title": "Buy Bio metric Sensor at 50%off*",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor",
                        "image": {
                            "url": "https://cdn.shopify.com/s/files/1/0707/1468/7765/articles/Rectangle_26.png?v=1674456240"
                        }
                    },
                    {
                        "id": "gid://shopify/Article/604529197333",
                        "title": "Buy Tensor Sensor at 50%off*",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor",
                        "image": {
                            "url": "https://cdn.shopify.com/s/files/1/0707/1468/7765/articles/Rectangle_26_1.png?v=1674457239"
                        }
                    },
                    {
                        "id": "gid://shopify/Article/604529230101",
                        "title": "Buy optic Sensor at 50%off*",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor",
                        "image": {
                            "url": "https://cdn.shopify.com/s/files/1/0707/1468/7765/articles/Rectangle_26_2.png?v=1674457348"
                        }
                    }
                ]
            }
        }
    }

    const uiFeaturedProductMapper = React.useMemo(() => {
        let productArray: {}[] = featuredCollection[0]?.products?.nodes
        // let productArray : {}[] = mockData
        let firstFourElementsCount = 4
        let restAllElementsCount = productArray?.length - 4
        let firstFourElements = productArray?.slice().splice(0, firstFourElementsCount);
        let restAllElements = productArray?.slice().splice(-restAllElementsCount);
        if (featuredCollection.length > 4) {
            // if(fraturedCollection.length){
            return [firstFourElements?.map((element, index) => {
                //let indexForDivider = index + 1;
                let putDevider = index === 0 ? false : true;
                return (<>
                    <div className="hid-grid__column hid-grid__column--12-xs hid-grid__column--6-sm hid-grid__column--3-md hid-grid__column--3-lg">
                        <div className="hid-box">
                            {putDevider ? <Divider orientation="vertical" variant="middle" style={{
                                height: "30%",
                                border: "1px solid #CCCCCC",
                                alignSelf: "center"
                            }} /> : <></>}
                            <ProductCard product={element} mock={false} />
                        </div>
                    </div>

                </>
                )
            }),
            restAllElements?.map((element, index) => {
                let indexForDivider = index + 1;
                let putDevider = indexForDivider % 4 === 0 ? false : true;
                return (
                    <div className="hid-grid__column hid-grid__column--12-xs hid-grid__column--6-sm hid-grid__column--3-md hid-grid__column--3-lg">
                        <div className="hid-box">
                            {putDevider ? <Divider orientation="vertical" variant="middle" style={{
                                height: "30%",
                                border: "1px solid #CCCCCC",
                                alignSelf: "center"
                            }} /> : <></>}
                            <ProductCard product={element} mock={false} />
                        </div>
                    </div>
                )
            })
            ]
        } else {
            return [productArray?.map((element, index) => {
                //let indexForDivider = index + 1;
                let putDevider = index === 0 ? false : true;
                console.log("putDevider", putDevider)
                return (
                    <div className="hid-grid__column hid-grid__column--12-xs hid-grid__column--6-sm hid-grid__column--3-md hid-grid__column--3-lg">
                        <div className="hid-box">
                            {putDevider ? <Divider orientation="vertical" variant="middle" style={{
                                height: "40%",
                                border: "1px solid #CCCCCC",
                                alignSelf: "center"
                            }} /> : <></>}
                            <ProductCard product={element} mock={false} />
                        </div>
                    </div>
                )
            })]
        }

    }, [featuredCollection]);

    return (
        <div className="home-container">

            <Heading />

            <ImageCarousel articles={memoisedBanners} />
            {/* <ImageCarousel articles={apiData.data.articles.nodes} /> */}

            {
            contracts?.length === 0 ?
            // contracts?.length !== 0 ?
             <></> :
              <div className="my-service-contracts">
                <div className="contract-title">
                    My Service Contracts
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="hid-grid" style={{ width: '95%' }}>

                        {contracts.slice(0, 3).map((element, index) => (
                            <div className="hid-grid__column hid-grid__column--12-sm hid-grid__column--4-lg">
                                <div className="hid-box">
                                    <ServiceContractCard key={index} cardDetails={element} />
                                </div>
                            </div>
                        ))}
                         {/* {[
				{
					Active: "Y",
					ContractEndDate: "2024-04-10T11:50:38.000Z",
					ContractId: "5344730448181",
					ContractName: "API validation",
					ContractStartDate: "2023-04-11T11:50:38.000Z",
					Created: "2023-04-11T11:53:25.000Z",
					LastUpdated: "2023-04-11T11:53:25.000Z",
					OnboardingStageId: "HID001",
					PartnerId: "maryagnel.sharon@infosys.com",
					PartnerManagerId: "neilkiran_vulchi@infosys.com",
					Status: "Processing",
				},
				{
					Active: "Y",
					ContractEndDate: "2024-04-10T11:50:38.000Z",
					ContractId: "5344730448181",
					ContractName: "API validation",
					ContractStartDate: "2023-04-11T11:50:38.000Z",
					Created: "2023-04-11T11:53:25.000Z",
					LastUpdated: "2023-04-11T11:53:25.000Z",
					OnboardingStageId: "HID001",
					PartnerId: "maryagnel.sharon@infosys.com",
					PartnerManagerId: "neilkiran_vulchi@infosys.com",
					Status: "Expired",
				},
                {
					Active: "Y",
					ContractEndDate: "2024-04-10T11:50:38.000Z",
					ContractId: "5344730448181",
					ContractName: "API validation",
					ContractStartDate: "2023-04-11T11:50:38.000Z",
					Created: "2023-04-11T11:53:25.000Z",
					LastUpdated: "2023-04-11T11:53:25.000Z",
					OnboardingStageId: "HID001",
					PartnerId: "maryagnel.sharon@infosys.com",
					PartnerManagerId: "neilkiran_vulchi@infosys.com",
					Status: "Expired",
				},
			].map((element, index) => (
                            <div className="hid-grid__column hid-grid__column--12-sm hid-grid__column--4-lg">
                                <div className="hid-box">
                                    <ServiceContractCard key={index} cardDetails={element} />
                                </div>
                            </div>
                        ))}  */}
                    </div>
                </div>
                <UiPopover anchorElForPopover={anchorElForPopover} setAnchorElForPopover={setAnchorElForPopover}/>
                <div className="see-more">
                    <div onClick={() => {
                        navigate('/my-account/service-contracts')
                    }} style={{ cursor: 'pointer' }}>
                        See More &nbsp;<Iconography icon="angleRight" />
                    </div>
                </div>

            </div>}

            <hr className="home-divider" />

            <div className="featured-packages">
                <div className="header-title">
                    Featured Packages
                </div>
                <div className="header-description">
                    {featuredCollection[0]?.description}
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="hid-grid" style={{ width: '95%' }}>

                        {uiFeaturedProductMapper[0]}

                        {packageSeeMore.hidden ? <></> : packageSeeMore.state ?
                            <>
                                {uiFeaturedProductMapper[1]}
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className="see-more">
                    <div
                        onClick={() => {
                            navigate('/products/hid-origo-mobile-identities')
                        }}
                        // onClick={handlePackageSeeClick}
                        style={{ cursor: 'pointer' }}>
                        See more &nbsp;<Iconography icon="angleRight" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HomePage;
