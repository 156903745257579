
export const myAccountSagaActionType = {
    callContractFindAllSaga: "callContractFindAllSaga",
    callAllOrdersSaga: "callAllOrdersSaga",
    callOrderDetailSaga: 'callOrderDetailSaga',
    callGetDevelopersForContractSaga: "callGetDevelopersForContractSaga",
    callAddDeveloperToContractSaga: "callAddDeveloperToContractSaga",
    callCreateContractSaga : 'callCreateContractSaga',
    callGetAppsForContractSaga: 'callGetAppsForContractSaga',
    callAddAppsToContractSaga: 'callAddAppsToContractSaga'
}


export const contractFindAllSagaAction: () => {
    type: string,
} = () => {
    return {
        type: myAccountSagaActionType.callContractFindAllSaga,
    }
}

export const allOrdersSagaAction: () => {
    type: string,
} = () => {
    return {
        type: myAccountSagaActionType.callAllOrdersSaga,
    }
}


export const createContractSagaAction : (orderId : string) => {
    type: string,
    payload : {
        orderId : string
    }
} = (orderId) => {
    return {
        type : myAccountSagaActionType.callCreateContractSaga,
        payload : {
            orderId : orderId
        }
    }
}

export const orderDetailSagaAction : (orderId : string) => {
    type: string,
    payload: {
        orderId: string
    }
} = (orderId) => {
    return {
        type: myAccountSagaActionType.callOrderDetailSaga,
        payload: {
            orderId: orderId
        }
    }
}

export const getDevelopersForContractSagaAction: (contractId: string) => {
    type: string,
    payload: {
        contractId: string
    }
} = (contractId) => {
    return {
        type: myAccountSagaActionType.callGetDevelopersForContractSaga,
        payload: {
            contractId: contractId
        }
    }
}

type developerPayloadType = {
    UserId: string,
    UserName: string,
    UserTypeId: string,
    UserCompany: string,
    UserAddress: string,
    UserEMAILId: string,
    Active: string,
    ContractId: string,
}

export const addDeveloperToContractSagaAction: (Developer: developerPayloadType) => {
    type: string,
    payload: {
        Developer: developerPayloadType
    }
} = (Developer) => {
    return {
        type: myAccountSagaActionType.callAddDeveloperToContractSaga,
        payload: {
            Developer: Developer
        }
    }
}

export const getAppsForContractSagaAction: (contractId: string) => {
    type: string,
    payload: {
        contractId: string
    }
} = (contractId) => {
    return {
        type: myAccountSagaActionType.callGetAppsForContractSaga,
        payload: {
            contractId: contractId
        }
    }
}

type applicationPayloadType = {
    ApplicationId: string
    ApplicationName: string
    Active: string
  }
export const addAppsToContractSagaAction: (Application: applicationPayloadType) => {
    type: string,
    payload: {
        Application: applicationPayloadType
    }
} = (Application) => {
    return {
        type: myAccountSagaActionType.callAddAppsToContractSaga,
        payload: {
            Application: Application
        }
    }
}