import React, { useState } from 'react';
import { Grid, Button, Divider } from '@mui/material';
import "@hid-galaxy-ui/galaxy-css/galaxy.css";
import './developers.scss'
import Iconography from '@hid-galaxy-ui/galaxy-react/components/Iconography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
// import Pagination from '@mui/material/Pagination';
import Pagination from '@hid-galaxy-ui/galaxy-react/components/Pagination';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import {
    getDevelopersForContractSagaAction,
    addDeveloperToContractSagaAction,
    getAppsForContractSagaAction,
    addAppsToContractSagaAction
} from '../../../../redux/saga/my-account/saga-actions';


const DevelopersApplications = ({ handleCloseModal, contractId }: any) => {
    const dispatch = useAppDispatch();
    const { developers, applications } = useAppSelector((state) => state.my_account);
    console.table(applications)
    const [tabSwitch, setTabSwitch] = useState(true);
    const [contractDev, setContractDev] = useState(null);
    const [developerPage, setDeveloperPage] = useState(1);
    const [applicationPage, setApplicationPage] = useState(1);
    const [developerForm, setDeveloperForm] = useState
        ({
            formValue: {
                name: "",
                email: "",
                address: "",
                status: ""

            }
        });
    const [applicationForm, setApplicationForm] = useState
        ({
            formValue: {
                name: "",
                status: ""
            }
        });

    React.useEffect(() => {
        dispatch(getDevelopersForContractSagaAction(contractId))
        dispatch(getAppsForContractSagaAction(contractId))
    }, []);

    // React.useEffect(() => {
    //     setContractDev(developers);
    // }, [developers])

    const handleClickDeveloper = () => {
        setTabSwitch(true);
    }
    const handleClickApplication = () => {
        setTabSwitch(false);
    }

    const handleChangeDeveloper = (event: (React.ChangeEvent<any>)): void => {
        event.preventDefault();
        setDeveloperForm({
            formValue: {
                ...developerForm.formValue,
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleDeveloperSubmit = () => {
        const Developer = {
            UserId: developerForm.formValue.name,
            UserName: developerForm.formValue.email,
            UserTypeId: "HTPD",
            UserCompany: "HID",
            UserAddress: "HID",
            UserEMAILId: developerForm.formValue.email,
            Active: developerForm.formValue.status,
            ContractId: contractId,
        }

        console.log(Developer);

        dispatch(addDeveloperToContractSagaAction(Developer));

        clearDeveloperFormFields();

    }

    const clearDeveloperFormFields = () => {
        setDeveloperForm({
            formValue: {
                ...developerForm.formValue,
                name: "",
                email: "",
                address: "",
                status: "",
            },
        });
    }

    const handleApplicationChange = (event: (React.ChangeEvent<any>)): void => {
        event.preventDefault();
        setApplicationForm({
            formValue: {
                ...applicationForm.formValue,
                [event.target.name]: event.target.value,
            },
        });
    };

    const handleApplicationSubmit = () => {
        const Application = {
            ApplicationId: contractId,
            ApplicationName: applicationForm.formValue.name,
            Active: applicationForm.formValue.status
        }

        console.log(Application);

        dispatch(addAppsToContractSagaAction(Application));

        clearApplicationFormFields();

    }

    const clearApplicationFormFields = () => {
        setApplicationForm({
            formValue: {
                ...applicationForm.formValue,
                name: "",
                status: "",
            },
        });
    }

    return (
        <div className="container">
            <div className="cross">
                <Iconography style={{ width: "21px", height: "21px", cursor: 'pointer' }} icon="xmark" onClick={handleCloseModal} />
            </div>
            <div className="nav-info">
                <div onClick={handleClickDeveloper} className={tabSwitch ? 'active' : 'inactive'} style={{ width: '91px' }}>Developers</div>
                <Divider
                    orientation="vertical"
                    flexItem={true}
                    style={{ margin: '0 16px', border: "1px solid #BEBEBE", height: '22px', alignSelf: "center" }}
                    variant="middle"
                />
                <div onClick={handleClickApplication} className={tabSwitch ? 'inactive' : 'active'}>Applications</div>
            </div>
            {tabSwitch ?
                <>
                    <Grid container columns={14} className="header-box">
                        <Grid className="header-item" item sm={4}>
                            <div>Name</div>
                            <div className="sort-btn">
                                <ArrowDropUpIcon className='icon' fontSize='small' />
                                <ArrowDropDownIcon className='icon' fontSize='small' />
                            </div>
                        </Grid>
                        <Grid className="header-item" item sm={5}>
                            <div>Email</div>
                            <div className="sort-btn">
                                <ArrowDropUpIcon className='icon' fontSize='small' />
                                <ArrowDropDownIcon className='icon' fontSize='small' />
                            </div>
                        </Grid>
                        <Grid className="header-item" item sm={3}>
                            <div>Status</div>
                            <div className="sort-btn">
                                <ArrowDropUpIcon className='icon' fontSize='small' />
                                <ArrowDropDownIcon className='icon' fontSize='small' />
                            </div>
                        </Grid>
                    </Grid>

                    <Divider flexItem={true} style={{ border: '1px solid #DDDDDD', marginTop: '1rem', marginBottom: '1rem' }} />

                    <Grid container columns={14} className="input-box">
                        <Grid className="input-item" item sm={4}>
                            <input type="text" name="name" value={developerForm.formValue.name} onChange={handleChangeDeveloper} />
                        </Grid>
                        <Grid className="input-item" item sm={5}>
                            <input type="email" name="email" value={developerForm.formValue.email} onChange={handleChangeDeveloper} />
                        </Grid>
                        <Grid className="input-item" item sm={3}>
                            <select name="status" value={developerForm.formValue.status} onChange={handleChangeDeveloper}>
                                <option value="" selected disabled>Select</option>
                                <option value="Y">Active</option>
                                <option value="N">Inactive</option>
                            </select>
                        </Grid>
                        <Grid className="input-item" item sm={1}>
                            <Iconography icon="check"
                                style={{ transform: 'scale(1.2)' }}
                                className="clickable"
                                onClick={handleDeveloperSubmit} />
                        </Grid>
                        <Grid className="input-item" item sm={1}>
                            <Iconography icon="xmark"
                                className="clickable"
                                onClick={clearDeveloperFormFields} />
                        </Grid>
                    </Grid>

                    {developers?.slice((developerPage - 1) * 5, developerPage * 5).map((dev: any, index: number) => (
                        <>
                            <Divider key={index + Math.random()} flexItem={true} style={{ border: '1px solid #DDDDDD' }} />
                            <Grid key={index + Math.random() + 1} container columns={14} className="table-box">
                                <Grid className="table-item" item sm={4}>
                                    <div>{dev.UserId}</div>
                                </Grid>
                                <Grid className="table-item" item sm={5}>
                                    <div>{dev.UserEMAILId}</div>
                                </Grid>
                                <Grid className="table-item" item sm={3}>
                                    <div>{dev.Active === "Y" ? "Active" : "Inactive"}</div>
                                </Grid>
                                <Grid className="table-item" item sm={1}>
                                    <Iconography icon="pen" className="clickable" />
                                </Grid>
                                <Grid className="table-item" item sm={1}>
                                    <Iconography icon="trashCan" className="clickable" />
                                </Grid>
                            </Grid>
                        </>
                    ))}

                    <Grid container columns={14} className="bottom-box">
                        <Grid className="bottom-item" item sm={14}>
                            <Pagination
                                currentPageNumber={1}
                                totalRecords={developers?.length}
                                pageSize={5}
                                onPageNumberClick={(pageNumber) => setDeveloperPage(pageNumber)}
                                border
                            />
                        </Grid>
                    </Grid>
                </> :
                <div style={{ paddingBottom: '2rem' }}>
                    <Grid container columns={14} className="header-box">
                        <Grid className="header-item" item sm={9}>
                            <div>Application Name</div>
                            <div className="sort-btn">
                                <ArrowDropUpIcon className='icon' fontSize='small' />
                                <ArrowDropDownIcon className='icon' fontSize='small' />
                            </div>
                        </Grid>
                        <Grid className="header-item" item sm={3}>
                            <div>Status</div>
                            <div className="sort-btn">
                                <ArrowDropUpIcon className='icon' fontSize='small' />
                                <ArrowDropDownIcon className='icon' fontSize='small' />
                            </div>
                        </Grid>
                    </Grid>

                    <Divider flexItem={true} style={{ border: '1px solid #DDDDDD', marginTop: '1rem', marginBottom: '1rem' }} />

                    <Grid container columns={14} className="input-box">
                        <Grid className="input-item" item sm={9}>
                            <input type="text" name="name" value={applicationForm.formValue.name} onChange={handleApplicationChange} />
                        </Grid>
                        <Grid className="input-item" item sm={3}>
                            <select name="status" value={applicationForm.formValue.status} onChange={handleApplicationChange}>
                                <option value="" selected disabled>Select</option>
                                <option value="Y">Active</option>
                                <option value="N">Inactive</option>
                            </select>
                        </Grid>
                        <Grid className="input-item" item sm={1}>
                            <Iconography icon="check"
                                style={{ transform: 'scale(1.2)' }}
                                className="clickable"
                                onClick={handleApplicationSubmit} />
                        </Grid>
                        <Grid className="input-item" item sm={1}>
                            <Iconography icon="xmark"
                                className="clickable"
                                onClick={clearApplicationFormFields} />
                        </Grid>
                    </Grid>

                    {applications?.map((app, index) => (
                        <>
                            <Divider key={index + Math.random() * 2} flexItem={true} style={{ border: '1px solid #DDDDDD' }} />
                            <Grid key={index + Math.random() * 3} container columns={14} className="table-box">
                                <Grid className="table-item" item sm={9}>
                                    <div>{app.ApplicationName}</div>
                                </Grid>
                                <Grid className="table-item" item sm={3}>
                                    <div>{app.Active === "Y" ? "Active" : "Inactive"}</div>
                                </Grid>
                                <Grid className="table-item" item sm={1}>
                                    <Iconography icon="pen"
                                        className="clickable" />
                                </Grid>
                                <Grid className="table-item" item sm={1}>
                                    <Iconography icon="trashCan"
                                        className="clickable" />
                                </Grid>
                            </Grid>
                        </>
                    ))}

                    {/* <Grid container columns={14} className="bottom-box">
                        <Grid className="bottom-item" item sm={14}>
                            <Pagination
                                currentPageNumber={1}
                                totalRecords={30}
                                pageSize={5}
                                onPageNumberClick={(pageNumber) => setApplicationPage(pageNumber)}
                                border
                            />
                        </Grid>
                    </Grid> */}
                </div>
            }
        </div>
    )
}

export default DevelopersApplications;