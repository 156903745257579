import {createSlice} from "@reduxjs/toolkit"

type initialstateType = {
    loginDetail : {}[],
    errorInfo : {
        severity : string,
        errorMsg : string,
        open : boolean
    }
}

var initialstate : initialstateType = {
    loginDetail : [],
    errorInfo : {
        severity : "",
        errorMsg : "",
        open : false
    }

}

const authSlice = createSlice({
    name : "auth",
    initialState : initialstate,
    reducers : {
        setLoginDetails : (state, action) => {
            console.log("action in auth slice", action.payload)
            state.loginDetail = action.payload
        },
        logOut : (state) => {
            state.loginDetail = [];
            localStorage.clear()
        },
        errorInfoTrigger : (state, action) => {
           state.errorInfo = action.payload
        },
        reset : (state) => {
            state.loginDetail  = []
        }
        
    }
})

export const authReducer = authSlice.reducer;
export const authSliceAction = authSlice.actions;