import { useAppDispatch } from "../redux";
import { myAccountSliceAction } from "../redux/slices/my-account"
import { cartSliceAction } from "../redux/slices/cart"
import { authSliceAction } from "../redux/slices/auth"

const useLogoutHandler: () => any[] = () => {
    const dispatch = useAppDispatch();
    const dispatcher: () => void = () => {
        dispatch(cartSliceAction.reset());
        dispatch(authSliceAction.reset());
        dispatch(myAccountSliceAction.reset());
        localStorage.clear();
    }

    return [dispatcher]
}

export default useLogoutHandler;