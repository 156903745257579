import { createSlice } from "@reduxjs/toolkit"
import { productDetailType, featureSectionType, recommendedSectionType } from "./Types"

type initialstateType = {
    collection: {
        productId : string,
        productDetail: productDetailType,
        featureSection?: featureSectionType,
        recommendedSection?: recommendedSectionType 
    },
    status: {
        loading: boolean,
        error: boolean,
        errorMessage: "",
        errorType: "",
    }
}

var initialstate: initialstateType = {
    collection: {
        productId : "",
        productDetail: {},
        featureSection:[],
        recommendedSection:[]
    },
    status: {
        error: false,
        errorMessage: "",
        errorType: "",
        loading: false
    }
}

const productDetail = createSlice({
    name: "pdp",
    initialState: initialstate,
    reducers: {
        set_productDetail_featureSection_recommendedSection: (state, action) => {
            console.log("set_productDetail_featureSection_recommendedSection", action.payload)
            state.collection = {
                productId : action?.payload?.productId,
                productDetail: { ...action?.payload?.productDetail },
                featureSection: [ ...action?.payload?.featureSection ],
                recommendedSection: [...action?.payload?.recommendedSection],
            }
        },
        setLoadingStatus: (state, action) => {
            console.log("setLoadingStatus", action.payload)
            state.status.loading = action.payload

        }
    }
})

export const productDetailScileReducer = productDetail.reducer;
export const productDetailSliceAction = productDetail.actions;
