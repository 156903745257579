import React from 'react';
import { useAppDispatch, useAppSelector } from "../../redux";
import { getFAQ } from "../../redux/saga/faq/saga-actions"
import { Skeleton } from '@mui/material';
import './faq.scss'

const FaqPage = () => {
    const dispatch = useAppDispatch();
    const { title, body, status } = useAppSelector((state) => state.faq);
    const { loading } = status;
    const temp = React.useRef<HTMLDivElement>()

    React.useEffect(() => {
        window.scrollTo({ top: 0 });
        dispatch(getFAQ());
        if (body) {
            temp.current.innerHTML = body;
        }
    }, []);

    React.useEffect(() => {
        if (body) {
            temp.current.innerHTML = body;
        }
    }, [body])

    return (
        <div className='faq-container'>
            {loading ?
                <>
                    <div className='loading'>
                        <Skeleton style={{
                            height: "100px",
                            width: "100%",
                            borderRadius: "10px",
                            marginBottom: "32px",
                        }} variant='rectangular' />
                        <Skeleton style={{
                            height: "500px",
                            width: "100%",
                            borderRadius: "10px",
                        }} variant='rectangular' />
                    </div>
                    <div ref={temp} style={{ visibility: 'hidden' }}></div>
                </>
                :
                <>
                    <div className='title'>{title}</div>
                    <div ref={temp} className='faq-body'></div>
                </>
            }

        </div>

    )
}

export default FaqPage;