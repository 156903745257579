

// creating actionTypes so that we do not have a mismatch of action type stings in saga!!
 export const authSagaActionTypes = {
    callAuthSaga : 'callAuthSaga',
}


export const getLoginSagaActions : (data : { email: string, password: string }) => {
    type: string,
    payload? : { email: string, password: string }
} = (data) => {
    console.log('=====> getLoginSagaActions ', data);

    return {
        type : authSagaActionTypes.callAuthSaga,
        payload : data
    }
}
