import React from 'react';
import { Grid, Divider, Popover, Typography } from '@mui/material'
import Iconography from '@hid-galaxy-ui/galaxy-react/components/Iconography';
import './MyAccount.scss'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux';
import { authSliceAction } from '../../redux/slices/auth';
import { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover} from '../../common/components/CustomPopover';
import useLogoutHandler from "../../hooks/useLogoutHandler"

const MyAccount = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const pathName = location.pathname;
    const navigate = useNavigate();
    const [anchorElForPopover, setAnchorElForPopover] = React.useState<HTMLElement | null>(null);
    const [triggerLogout] = useLogoutHandler();
    console.log("pathName --> my-account", pathName);

    React.useEffect(() => {
        window.scrollTo({ top: 0 });
        pathName === '/my-account' ? navigate('order-history') : (() => { })();
    }, [pathName]); // componentWillUpdate when there is a change in path name

    return (
        <div className="my-account-container">
            <div className="page-head">
                <div className="my-account-name">My Account</div>
                <div
                    className="filter"
                    aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    All &nbsp;<Iconography icon='angleDown' />
                </div>

            </div>

            <Grid container columns={16}>
                <Grid item md={3} display={'flex'} justifyContent={'space-between'}>
                    <div className="menu-list">
                        <div
                            className='menu-item'
                            aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            User and Roles
                        </div>
                        <NavLink to="order-history" className={({ isActive }) => (pathName === ('/my-account/order-history') ? 'active' : 'inactive')}>
                            Orders History
                        </NavLink>
                        <NavLink to="service-contracts" className={({ isActive }) => (pathName === ('/my-account/service-contracts') ? 'active' : 'inactive')}>
                            Service Contracts
                        </NavLink>
                        <div className='menu-item' onClick={() => {
                            //dispatch(authSliceAction.logOut();
                            triggerLogout();}}>
                            Logout
                        </div>
                    </div>
                    <Divider orientation='vertical' className="v-divider" />
                </Grid>
                
                <Grid item style={{
                    minHeight : "500px"
                }} md={13}>                 
                    <Outlet />
                </Grid>
            </Grid>

            <UiPopover anchorElForPopover={anchorElForPopover} setAnchorElForPopover={setAnchorElForPopover}/>

        </div>
    )
}

export default MyAccount;