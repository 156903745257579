import axios from 'axios';

export const axiosGraphqlInstance = axios.create({
  baseURL: process.env.REACT_APP_SHOPIFY_AXIOS_BASEURL, // --prod
  timeout: 3000,
  headers: {
      'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_AXIOS_ACCESS_TOKEN, // --prod
      'Content-Type' : 'application/json'

  }
});

export const axiosRestInstance = axios.create({
    baseURL: process.env.REACT_APP_NODE_AXIOS_BASEURL,
    timeout: 3000,
    headers: {
        'Content-Type' : 'application/json'
    }
});

// export default axiosInstance;

  