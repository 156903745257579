

// creating actionTypes so that we do not have a mismatch of action type stings in saga!!
export const productListSagaActionTypes = {
    callGetCollectionSaga : 'callGetCollectionSaga',
}

// these are the saga acton that are need to be dispatched from components;
export const getCollectionAction : (subTypeData? : string, subTypeIndex? : number) => {
    type: string,
    payload? : {
        subType : string,
        subTypeIndex : number,
    }
} = (subTypeData, subTypeIndex) => {
    return {
        type : productListSagaActionTypes.callGetCollectionSaga,
        payload :  {
            subType : subTypeData,
            subTypeIndex : subTypeIndex
        }
    }
}

