import React from 'react'
import { Box } from '@mui/system'
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router';
import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { useAppDispatch, useAppSelector } from "../../redux"
import { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover } from '../../common/components/CustomPopover';
import ProductCard from "../../common/components/ProductCard/ProductCard";
import Iconography from '@hid-galaxy-ui/galaxy-react/components/Iconography';
import useProductListPageRoute from '../../hooks/useProductListPageRoute';

const ProductsListPage: React.FC<{}> = (props) => {
    const { collection, status } = useAppSelector((state) => state.plp);
    const { loading } = status;
    console.log("collection, status", collection, status);
    const dispatch = useAppDispatch();
    const { productListRoutesArray, getTitle, getRouteIndex, dispatchIt } = useProductListPageRoute();
    console.log('useProductListPageRoute --helper.productListRouteObject --getIndex', productListRoutesArray);
    const routeLocation = useLocation();
    const [anchorElForPopover, setAnchorElForPopover] = React.useState<HTMLElement | null>(null);
    console.log("location", routeLocation.pathname);
    const routeLoctionSplited = routeLocation.pathname.split('/');
    const routeLocationEndpoint = routeLoctionSplited[routeLoctionSplited.length - 1];
    // console.log("routeLocationEndpoint", routeLocationEndpoint);
    // const menuName = routeLocationEndpoint.split('-').map((element, index) => {
    //     return element.split('').map((subElelment, subIndex) => {
    //         return subIndex === 0 ? subElelment.toLocaleUpperCase() : subElelment
    //     }).join('')
    // }).join(" ");

    // const collectionTypeIndexTaker: (route_location: string) => number = (route_location) => {
    //     switch (route_location) {
    //         case "hid-origo-mobile-identities":
    //             return 0
    //         case "hid-origo-api":
    //             return 1
    //         case "hid-origo-sdk":
    //             return 2
    //         case "aero":
    //             return 3
    //         default:
    //             return 0
    //     }
    // }
    // const subCollection = collection[collectionTypeIndexTaker(routeLocationEndpoint)];
    const subCollection = collection[getRouteIndex(routeLocationEndpoint)];
    const sortedCollection = React.useMemo(() => subCollection, [collection, routeLocation]);

    // const routeDependentDispatch: (route_location: string) => void = (route_location) => {

    //     switch (route_location) {
    //         case "hid-origo-mobile-identities":
    //             if (collection[collectionTypeIndexTaker("apple-wallet")]?.length === 0)
    //                 dispatch(getCollectionAction(route_location));
    //             break;
    //         case "hid-origo-api":
    //             if (collection[collectionTypeIndexTaker("hid-origo-api")]?.length === 0)
    //                 dispatch(getCollectionAction(route_location));
    //             break;
    //         case "hid-origo-sdk":
    //             if (collection[collectionTypeIndexTaker("hid-origo-sdk")]?.length === 0)
    //                 dispatch(getCollectionAction(route_location));
    //             break;
    //         case "aero":
    //             if (collection[collectionTypeIndexTaker("aero")]?.length === 0)
    //                 dispatch(getCollectionAction(route_location));
    //             break;
    //         default:
    //             break;
    //     }

    // }

    React.useInsertionEffect(() => {
        window.scrollTo({
            top: 0
        })
        // routeDependentDispatch(routeLocationEndpoint);
        if (collection[getRouteIndex(routeLocationEndpoint)]?.length === 0) {
            dispatchIt(routeLocationEndpoint);
        }
    }, [routeLocation])

    const uiMapper = React.useMemo(() => {
        return sortedCollection?.map((element: any, index: number) => {
            let elementIdSplited = element?.id.split('/') //gid://shopify/Collection/433369022741
            // let boxKey = elementIdSplited[elementIdSplited?.length - 1] + Math.random()
            let boxKey = Math.random()
            return (<>
                <Box sx={{
                    padding: '2rem',
                    paddingRight: '0'
                }}>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid container
                            style={{ paddingBottom: "24px", paddingLeft: "3rem" }}
                        >
                            <Typography fontSize={"32px"} color={"#00539B"} fontWeight={450}>{element?.title}</Typography>
                        </Grid>
                        <Grid container columns={{ xs: 4, sm: 8, md: 20 }}
                            justifyContent={"flex-start"} paddingLeft={"7px"}>
                            {element?.products?.nodes.map((subElement: any, subIndex: any) => {
                                // let subElementIdSplited = subElement?.id.split("/");
                                // let productKey = subElementIdSplited[subElementIdSplited.length - 1]
                                // console.log("boxKey , productKey", boxKey, productKey)
                                return (
                                    <Grid item width={"260px"} key={index}
                                        display={"flex"} height={"18rem"} alignItems={"center"}>
                                        <ProductCard plpRoute={getTitle(routeLocationEndpoint)} product={subElement} mock={false} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Box>
                {/* <Divider key={boxKey + ".divider"} variant="middle" /> */}
            </>)
        })
    }, [sortedCollection]);

    const skeletonOnLoading = () => {
        return [1].map(() => {
            return (<><Box sx={{
                // marginTop: '2%',
                // backgroundColor: "red",
                padding: '4%',
                paddingLeft: '72px',
                paddingTop: '2rem'
            }}>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container spacing={3} marginBottom={"2%"} >
                        <Grid item xs>
                            <Skeleton style={{
                                borderRadius: "10px",
                                height: "64px",
                                width: " 380px",
                            }} variant='rectangular' />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"flex-start"} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 20 }}>
                        {[1, 2, 3, 4, 5].map(() => {
                            return (
                                <Grid item xs={2} sm={4} md={4} >
                                    <Skeleton style={{
                                        borderRadius: "10px",
                                        height: "255px",
                                        width: "186px",
                                    }} variant='rectangular' />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Box>

            </>)
        })
    }


    console.info("collection -> item", sortedCollection)


    return (<>
        <div style={{
            display: "flex",
            flexDirection: 'column',
            marginTop: "140px",  // ----> chnaged 140px
            background: "white",
            minHeight: "80vh"
        }}>
            <div>
                <Box sx={{
                    flexGrow: 1,
                    // backgroundColor: "red" 
                }}>
                    <Grid container spacing={2} columns={16}>
                        <Grid item xs={8} textAlign={"left"} justifyContent={"center"} sx={{
                            // backgroundColor: "yellow",
                            // border: '1px solid black'
                        }}>
                            <Stack marginLeft={"3rem"} spacing={2}>
                                <Breadcrumbs separator={<Iconography icon="angleRight" />} >
                                    {[
                                        <h6 style={{ color: "#00539B", fontSize: "14px", fontFamily: "'Roboto'" }} >Home</h6>,
                                        <h6 style={{ color: "#00539B", fontSize: "14px", fontFamily: "'Roboto'" }} >Products</h6>,
                                        <h6 style={{ color: "#222222", fontSize: "14px", fontFamily: "'Roboto'" }} >{getTitle(routeLocationEndpoint)}</h6>
                                    ]}
                                </Breadcrumbs>
                            </Stack>
                        </Grid>
                        <Grid item textAlign={"right"} xs={8} sx={{
                            // backgroundColor: "yellow"
                        }}>
                            <Button style={{
                                // backgroundColor : "red",
                                // marginTop: "2%",
                                marginRight: '80px',
                                textTransform: 'none',
                                fontSize: '16px'

                            }}
                                aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >
                                <TuneIcon></TuneIcon>&nbsp; Filter
                            </Button>

                        </Grid>
                    </Grid>
                </Box>
                <Grid container direction={"column"} >
                    {/* <Grid item xs={4} style={{
                    display: "flex",
                    alignItems: "flex-end",
                    paddingLeft: "2rem",
                    marginTop: "4rem"
                }}>
                    <Typography style={{
                        paddingLeft: "3rem",
                        fontSize: "36px",
                        fontFamily: 'Roboto',
                        fontWeight: '700',
                        fontStyle: 'normal',
                        color: "#00539B"
                    }}>
                        {/* {menuName} */}
                    {/* {getTitle(routeLocationEndpoint)} */}
                    {/* </Typography> */}
                    {/* </Grid> */}

                    {loading && subCollection.length === 0 ? skeletonOnLoading() : uiMapper}

                </Grid>
            </div>

            <UiPopover anchorElForPopover={anchorElForPopover} setAnchorElForPopover={setAnchorElForPopover} />

        </div>
    </>)

}

export default React.memo(ProductsListPage);
