import { call, put, takeLatest, } from "redux-saga/effects"
import { authSagaActionTypes } from "./saga-actions"
import { authSliceAction } from '../../slices/auth'
import {axiosGraphqlInstance} from "../../../common/axios"

type LoginDataResponseType = {
    data: {
        customerAccessTokenCreate: {
            customerAccessToken: { accessToken: string, expiresAt: string }
            customerUserErrors: []
        }
    }
}

const getAuthDetailAPI = async (userDetail: { email?: string, password?: string }) => {
    // console.log('====== getAuthDetailAPI ----', userDetail.email, userDetail.password)
    let response = await axiosGraphqlInstance.post<LoginDataResponseType | {}>('/graphql.json',
        {
            query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
        customerAccessTokenCreate(input : $input) {
          customerUserErrors {
            code
            field
            message
          }
          customerAccessToken {
            accessToken
            expiresAt
          }
        }
      }`,
            variables: {
                "input": {
                    "email": userDetail?.email,
                    "password": userDetail?.password
                }
            }
        }
    )
        .then((response) => {
            if (response.status === 200) {
                return response
                // localStorage.setItem('dataKey', JSON.stringify(data));
                // console.log('=====>getItem()', localStorage.getItem('dataKey'))
            }
        })
        .catch((error) => {
            throw error
        })
    // console.log("2022-10/products.json --> authSlice", response)
    return response.data
}

function* getLogin(actions: {
    type: string,
    payload: { email: string, password: string }
}) {
    try {
        // const data: [] = yield call(getAuthDetailAPI);
        const resonse: LoginDataResponseType = yield call(getAuthDetailAPI, actions.payload);
        // console.log("data in auth saga", resonse.data)
        if (resonse.data.customerAccessTokenCreate.customerUserErrors.length === 0) {
            yield put(authSliceAction.setLoginDetails(resonse.data))
            localStorage.setItem("user-token", resonse.data.customerAccessTokenCreate.customerAccessToken.accessToken);
            localStorage.setItem("user-token-expiry-date", resonse.data.customerAccessTokenCreate.customerAccessToken.expiresAt);
            localStorage.setItem("user-emailId", actions?.payload?.email);
        } else {
            yield put(authSliceAction.errorInfoTrigger({
                severity: "error",
                errorMsg: "Invalid username or password!",
                open: true
            }))
        }

        // console.log("saga is up and running", actions.payload);

    } catch (e) {
        console.error('====>error from axios', e)
        yield put(authSliceAction.errorInfoTrigger({
            severity: "error",
            errorMsg: "Technical Error!",
            open: true
        }))
        // console.log("saga is up and running from erro",   actions);
    }
}

function* authSaga() {
    yield takeLatest(authSagaActionTypes.callAuthSaga, getLogin);

}

export default authSaga;