import { createSlice } from "@reduxjs/toolkit"
// import { productDetailType, featureSectionType, recommendedSectionType } from "./Types"

type initialstateType = {
    allOrders: {
        "id"?: string,
        "displayName"?: string,
        "orders"?: {
            "edges": {
                "node": {
                    "id": string,
                    "name": string,
                    "orderNumber": number,
                    "processedAt": string,
                    "lineItems": {
                        "nodes": {
                            "variant": {
                                "image": {
                                    "url": string | null
                                }
                            }
                        }[],
                    },
                    "totalPrice"?: {
                        "amount": string
                    }
                }
            }[]
        }
    },
    contracts: {
        Active: string
        ContractEndDate: string
        ContractId: string
        ContractName: string
        ContractStartDate: string
        Created: string
        LastUpdated: string
        OnboardingStageId: string
        PartnerId: string
        PartnerManagerId: string
        Status: string
    }[],
    orderDetail: {
        "id"?: string | null,
        "name"?: string | null,
        "shippingAddress"?: {
            "firstName": string | null,
            "lastName": string | null,
            "address1": string | null,
            "address2": string | null,
            "zip": string | null,
            "phone": string | null
        },
        "orderNumber"?: number,
        "fulfillmentStatus"?: string | null,
        "financialStatus"?: string | null,
        "totalPrice"?: {
            "amount": string | null
        },
        "lineItems"?: {
            "nodes":
            {
                "title": string | null,
                "quantity": number,
                "variant": {
                    "image": string | null
                },
                "originalTotalPrice": {
                    "amount": string | null,
                    "currencyCode": string | null
                }
            }[],
        }
    },
    status: {
        loading: boolean,
        orderHistoryLoading: boolean,
        contractsLoading: boolean,
        error: boolean,
        errorMessage: string,
        errorType: string,
    },
    developers: {
        UserId: string
        UserName: string
        UserTypeId: string
        UserCompany: string
        UserAddress: string
        UserEMAILId: string
        Created: string
        LastUpdated: string
        Active: string
        ContractId: string
        DeveloperId: string
    }[],

    applications: {
        ApplicationId: string
        ApplicationName: string
        ValidationsRequested: string
        ValidationId: string
        Created: string
        LastUpdated: string
        Active: string
    }[]
}

var initialstate: initialstateType = {
    allOrders: {},
    orderDetail: {},
    contracts: [],
    status: {
        error: false,
        orderHistoryLoading: false,
        contractsLoading: false,
        errorMessage: "",
        errorType: "",
        loading: false
    },
    developers: [],
    applications: []
}

const myAccount = createSlice({
    name: "my-account",
    initialState: initialstate,
    reducers: {
        setContracts: (state, action) => {
            state.contracts = action.payload;
        },
        setAllOrders: (state, action) => {
            state.allOrders = action.payload;
        },
        setOrderDetail: (state, action) => {
            state.orderDetail = action.payload;
        },
        setLoadingStatus: (state, action) => {
            console.log("setLoadingStatus", action.payload);
            state.status.loading = action.payload;
        },
        setOrderHistoryLoadingStatus: (state, action) => {
            state.status.orderHistoryLoading = action.payload;
        },
        setContractsLoadingStatus: (state, action) => {
            state.status.contractsLoading = action.payload;
        },
        setDevelopers: (state, action) => {
            state.developers = action.payload;
        },
        setApplications: (state, action) => {
            state.applications = action.payload;
        },
        reset : (state) => {
            state.allOrders = {}
            state.applications = [];
            state.contracts = [];
            state.orderDetail = {};
            state.developers = [];
        }
    }
})

export const myAccountScileReducer = myAccount.reducer;
export const myAccountSliceAction = myAccount.actions;
