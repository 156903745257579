import { call, put, takeLatest, delay, select } from "redux-saga/effects"
import { productListSagaActionTypes } from "./saga-action"
import {axiosGraphqlInstance} from "../../../common/axios"
import { productListSliceAction } from "../../slices/product-list"
import { AxiosResponse } from "axios"

const productMenuApi = async (sub_type: string) => {
    let response = axiosGraphqlInstance.post<AxiosResponse<{
        menu: {
            items: { title: string, url: string }[]
        }
    }>>('/graphql.json', {
        query: ` {
            menu(handle: "${sub_type}") {
            title
            items {
              title
              url
            }
          }
        }`,
        variables: {

        }
    }).then((response) => response.data.data)
    return response;
}

const productCollectionApi = async (sub_package_type: string) => {
    console.log("sub_package_type", sub_package_type)
    let response = axiosGraphqlInstance.post<AxiosResponse>('/graphql.json', {
        query: ` {collection(handle: "${sub_package_type}") {
        id
        handle
        title
        description
        products(first: 250) {
          nodes{
            title
            id
            description
            priceRange{
              maxVariantPrice{
                amount
                currencyCode
              }
            }
            featuredImage{
              src
            }
          }
        }
    }}
    `,
        variables: {

        }
    }).then((response) => response.data.data.collection)
    return response;
}

function* getCollection(actions: {
    type: string,
    payload: {
        subType: string,
        subTypeIndex : number,
    }
}) {
    try {
        console.log("actions getCollection", actions)
        yield put(productListSliceAction.setLoadingStatus(true));
        console.log("plp saga" , actions)
        let productMenuApiResponse: {
            menu: {
                items: { title: string, url: string }[]
            }
        } = yield call(productMenuApi, actions.payload.subType);
        yield delay(600);
        let i: number = 0
        let items: any = productMenuApiResponse.menu.items
        while (items.length) {
            let result: {} = yield productCollectionApi(items[i].url.split("/")[items[i].url.split("/").length - 1])
            yield put(productListSliceAction.setCollection({
                subType: actions?.payload?.subType,
                subTypeIndex : actions?.payload?.subTypeIndex,
                data: result
            }))
            i++
        }

        yield put(productListSliceAction.setLoadingStatus(false));
        console.log("response in product saga!!", productMenuApiResponse.menu.items)

    } catch (e) {
        console.error(e)
        yield put(productListSliceAction.setLoadingStatus(false));
    }
}

function* productListSaga() {
    yield takeLatest(productListSagaActionTypes.callGetCollectionSaga, getCollection);
}

export default productListSaga;