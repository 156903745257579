import { createSlice } from "@reduxjs/toolkit"
import useProductListPageRoute from "../../../hooks/useProductListPageRoute"

type initialstateType = {
    collection: {}[][],
    status : {
        loading : boolean,
        error : boolean,
        errorMessage : string,
        errorType : string,
    }
}

var initialstate: initialstateType = {
    collection: [
        [], // will have the collection for apple-wallet -------> the array will be filed with object -> {}
        [], // will have the collection for hid-origo-api -------> the array will be filed with object -> {}
        [], // will have the collection for hid-origo-sdk -------> the array will be filed with object -> {}
        [], // will have the collection for aero -------> the array will be filed with object -> {}
    ],
    status : {
        error : false,
        errorMessage : "",
        errorType : "",
        loading : false
    }
}

// const collectionTypeIndexTaker: (route_location: string) => number = (route_location) => {
//     switch (route_location) {
//         case "hid-origo-mobile-identities":
//             return 0
//             case "hid-origo-api":
//             return 1
//             case "hid-origo-sdk":
//             return 2
//             case "aero":
//             return 3
//         default:
//             return 0
//     }
// }


const productList = createSlice({
    name: "plp",
    initialState: initialstate,
    reducers: {
        setCollection: (state, action) => {
            console.log("setCollection action in product slice", action)
            state.collection[action?.payload?.subTypeIndex].push(action.payload.data)
        },
        setLoadingStatus : (state, action) => {
            console.log("setLoadingStatus", action.payload)
            state.status.loading = action.payload
        }
    }
})

export const productListScileReducer = productList.reducer;
export const productListSliceAction = productList.actions;
