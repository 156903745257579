

// creating actionTypes so that we do not have a mismatch of action type stings in saga!!
export const productDetailSagaActionTypes = {
    callGetProductDetailSaga : 'callGetProductDetailSaga',
}

// these are the saga acton that are need to be dispatched from components;
export const getProductDetailAction : (productID? : string) => {
    type: string,
    payload? : {
        productID : string,
    }
} = (productID) => {
    return {
        type : productDetailSagaActionTypes.callGetProductDetailSaga,
        payload :  {
            productID : productID 
        }
    }
}

