// creating actionTypes so that we do not have a mismatch of action type stings in saga!!
export const cartSagaActionTypes = {
    callFetchCartSaga : "callFetchCartSaga",
    callCreateCartSaga : 'callCreateCartSaga',
    callUpdateCartSaga : 'callUpdateCartSaga',
    callDeleteCartSaga : 'callDeleteCartSaga',
}

type cartSagaActionPayloadType = {
    pdp : {
        productVariantId: string,
        featureSectionTitleAndVariantIdArray: {variantId: string, title: string}[] 
    },
    cartId : string | null,
    userToken : string | null,
    navigateFunction : () => void
}

export const cartCreateOrUpdateCartSagaActions : (data : cartSagaActionPayloadType) => {
    type: string,
    payload : cartSagaActionPayloadType,
} = (data) => {

    return {
        type : data?.cartId ? cartSagaActionTypes.callCreateCartSaga : cartSagaActionTypes.callUpdateCartSaga,
        payload : data
    }
}

export const fetchCartSagaActions : (cartId : string) => {
    type: string,
    payload : {
        cartId : string
    }
} = (cartId) => {

    return {
        type : cartSagaActionTypes.callFetchCartSaga,
        payload : {
            cartId : cartId,
        }
    }
}

export const deleteCartSagaActions : (data : {
    attributesIdArray: [],
    productVariantId: string,
    productCartLineId: string
} ) => {
    type: string,
    payload : {
        data : {
            attributesIdArray: [],
            productVariantId: string,
            productCartLineId: string
        }
    }
} = (data) => {
    return {
        type : cartSagaActionTypes.callDeleteCartSaga,
        payload : {
            data : data,
        }
    }
}
