import React from 'react';
import Slider, {Settings} from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './Carousel.scss';
import { Divider } from '@mui/material';

const Carousel = ({ products }: {
  products : JSX.Element[]  // pass the array of productCard in products property while calling this component!!
}) => {
  const settings : Settings = {
    dots: true,
    infinite: false,
    // autoplay : true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    rows: 1,
    // arrows: true,
    nextArrow: <ArrowForwardIosIcon classes={{
      root : 'arrow-icon-class'
    }} fontSize='large' className='slick-next' />,
    prevArrow: <ArrowBackIosNewIcon classes={{
      root : 'arrow-icon-class'
    }} fontSize='large' className='slick-prev' />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          // dots: true,
        },
      },
    ],
  };

  const memoizedProductCards = React.useMemo(() => {
    return products.map((element, index) => {
        return(<>
          <div style={{
            // backgroundColor : `#0053${index}B`
          }} className="card-wrapper">
             {index !== 0 ? <Divider orientation="vertical" variant="middle" style={{
                height : "40%",
                border : "1px solid #CCCCCC",
                alignSelf : "center"
              }} /> : <></>}
              {element}
          </div>
          </>
        )
    })
  }, [products])
  

  return (
    <div className="Carousel">
      <Slider {...settings} >
         {memoizedProductCards}
      </Slider>
    </div>
  );
}

export default Carousel;




// {products?.map((product: any, index: number) => (
//   <div key={index} className="card-div">
//     {/* <Card product={product} mock={true} onClick={() => console.log(product)}></Card> */}
//     {/* {Array.from(Array(5)).map((_, index) => ( */}
//     {/* // <Grid item xs={2} sm={4} md={4} key={index}> */}
//     {/* <Card  mock={true} /> */}
//     {/* // </Grid> */}
//     {/* ))} */}
    
//   </div>
// ))}