import { takeLatest } from "redux-saga/effects"
import { cartSagaActionTypes } from "./saga-actions"
import cartCreateOrUpdateCart from "./create-or-update"
import fetchCart from "./fetch"
import deleteItemsFromCart from "./delete";


function* cartSaga() {
    yield takeLatest(cartSagaActionTypes.callCreateCartSaga, cartCreateOrUpdateCart); // for calling create cart saga!!
    yield takeLatest(cartSagaActionTypes.callUpdateCartSaga, cartCreateOrUpdateCart); // for calling update cart saga!!
    yield takeLatest(cartSagaActionTypes.callFetchCartSaga, fetchCart); // for fetching cart items!
    yield takeLatest(cartSagaActionTypes.callDeleteCartSaga, deleteItemsFromCart); // for deleting cart items!
}

export default cartSaga;

