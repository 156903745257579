import React from 'react';
import Footer from "@hid-galaxy-ui/galaxy-react/components/Footer"
import "@hid-galaxy-ui/galaxy-css/galaxy.css";

const HIDFooter = () => {
    return (
        <a target={'_blank'}>
        <Footer
            primaryText="©2023 HID Global Corporation/ASSA ABLOY. All rights reserved."
            
            linkText={[
                {
                    text: "Privacy Statement",
                    link: "https://www.hidglobal.com/about/privacy",
                },
                {
                    text: "Modern Slavery",
                    link: "https://www.hidglobal.com/about/modern-slavery-statement",
                },
                {
                    text: "Terms of Use",
                    link: "https://www.hidglobal.com/about/terms-of-use",
                }
            ]}
        />
        </a>
    )
}

export default HIDFooter;