import React from "react";
import { useLocation, Outlet } from "react-router";
import Header from '../common/components/Header/Header';
import Footer from '../common/components/Footer/Footer';
import HomePage from './home';
import { UiPopover } from '../common/components/CustomPopover';

const LandingPage : React.FC<{}> = () => {
    const location = useLocation();
    const pathName = location.pathname.split('');
    const [anchorElForPopover, setAnchorElForPopover] = React.useState<HTMLElement | null>(null);
    // console.log("pathName ---> landingPage", pathName)
    return(<>
        <UiPopover anchorElForPopover={anchorElForPopover} setAnchorElForPopover={setAnchorElForPopover}/>
       <Header />
       {pathName.includes('/') && pathName.length === 1 ? <HomePage/> : <Outlet />}
       <Footer />
    </>)
}

export default LandingPage;