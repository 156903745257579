import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router';
import { useAppDispatch } from '../../redux'
import { getLoginSagaActions } from '../../redux/saga/auth/saga-actions'
import { authSliceAction } from "../../redux/slices/auth"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './styles.scss';
import { useFormik } from 'formik';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '@hid-galaxy-ui/galaxy-react/components/Button';
import { useNavigate } from 'react-router';
import { useAppSelector } from "../../redux"
import Logo from '@hid-galaxy-ui/galaxy-react/components/Logo'
import HIDFooter from '../../common/components/HIDFooter/HIDFooter';
import Stack from '@mui/material/Stack';
import {Snackbar, SnackbarProps} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import { InputAdornment, OutlinedInput, IconButton } from '@mui/material';

const Login = () => {

  const { loginDetail, errorInfo } = useAppSelector((state: any) => state.auth);
  console.log('=======> loginDetail', loginDetail, errorInfo);
  const navigate = useNavigate();
  const [data, setData] = useState([{ id: 'test coming' }]);

  // onKeyDown functionality --code
  const loginButtonRef = React.useRef<HTMLButtonElement>();
  const passwordInputRef = React.useRef<HTMLInputElement>();
  const onKeyDownHandler = (toRef : React.MutableRefObject<HTMLElement>) => {
    console.log("toRef.current.tagName", toRef?.current?.tagName)
    return (event : React.KeyboardEvent<HTMLElement>) => {
      if(event.key === "Enter"){
        if(toRef.current.tagName === 'BUTTON'){
          toRef.current.focus();
          toRef.current.click();
        }else{
          toRef.current.focus();
        }
      }
    }
  }
  //---------

  const [userDetail, setUserDetail] = useState<{
    user: {
      email: string,
      password: string
    };
  }>({
    user: {
      email: "",
      password: "",
    },
  });
  const useValidationHandler : () => string = () => {
    let textisEmpty = userDetail.user.email === "" || userDetail.user.password === ""
    if(errorInfo?.errorMsg && textisEmpty){
      return "Please enter username and password"
    }else if(errorInfo?.errorMsg){
      return errorInfo?.errorMsg
    }
  }
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  React.useEffect(() => {
    dispatch(authSliceAction.errorInfoTrigger({
      severity: "",
      errorMsg: "",
      open: false
    }))
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setUserDetail({
      user: {
        ...userDetail.user,
        [event.target.name]: event.target.value,
      },
    });
  };


  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const dispatch = useAppDispatch()


  return (
    <div className="login-container" style={{ marginTop: "0px" }}>
      <Box sx={{ bgcolor: "#FFFFFF" }}>
        <Grid container>
          <Grid item xs={6} md={8} className="login-header">
            {/* <Item>xs=6 md=8</Item> */}
            <div>
              {/* <div className="header-1">Welcome To The Services Portal<sup>TM</sup></div> */} {/* JSR : Removed "TM" as been instructed!! */}
              <div className="header-1">Welcome To The Services Portal</div>
              <div className="header-2">Portal for ordering the services for Packages and  Validations</div>
            </div>
          </Grid>
          <Grid item xs={6} md={4} className="right-view">
            <div className="login-box">
              <form
              // onSubmit={handleSubmit}
              >
                <div className="logo-head">
                  <Logo title='Services Portal' id="logo" />
                </div>

                <div className="uname-input">
                  <label htmlFor="exampleInputEmail1" className="label">
                    Username
                  </label>
                  <OutlinedInput
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={userDetail.user.email}
                    className="user-name"
                    onKeyDown={onKeyDownHandler(passwordInputRef)}
                  />
                </div>
                <div className="password-input">
                  <label htmlFor="exampleInputPassword1" className="label">
                    Password
                  </label>
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={userDetail.user.password}
                    onChange={handleChange}
                    className='password-field'
                    inputRef={passwordInputRef}
                    onKeyDown={onKeyDownHandler(loginButtonRef)}
                    // ref = {passwordInputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          style={{background:'#FAFAFA'}}
                          disableRipple = {true}
                        >
                          {showPassword ? <VisibilityOutlined />  : <VisibilityOffOutlined />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </form>
              {/* <div className="forgot-password">Forgot password</div> */}
              {/* <div className="error-msg">{errorInfo?.errorMsg && userDetail.user.email === "" ? "please enter the username and password" : errorInfo?.errorMsg}</div> fixed on 13th April 2023 by Yashvardhan */}
              <div className="error-msg">{useValidationHandler()}</div> {/*fixed on 13th April 2023 by Yashvardhan*/}
              <div className="box-bottom">
                <div className="create-account">
                  <a className="create-account" href='https://www.hidglobal.com/partners/origo-technology-partner-program' target={'_blank'}>Become a partner</a>
                </div>
                <Button elementRef={loginButtonRef} label="LOGIN" variant="primary"
                  onClick={() => {

                    dispatch(getLoginSagaActions({ ...userDetail.user }))
                  }}
                >
                  Login
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      <HIDFooter />
    </div>
  )
}

export default React.memo(Login)



{/* <Snackbar open={errorInfo?.open} autoHideDuration={6000}  onClose={() => {dispatch(authSliceAction.errorInfoTrigger({
                severity : "error",
                errorMsg : "Invalid userID or password!",
                open : false
            }))}}>
                <Alert onClose={() => {dispatch(authSliceAction.errorInfoTrigger({
                severity : "error",
                errorMsg : "Invalid userID or password!",
                open : false
            }))}} severity={errorInfo?.severity} sx={{ width: '100%' }}>
                  {errorInfo?.errorMsg}
                </Alert>
              </Snackbar> */}