import React from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useNavigate } from "react-router-dom";
import emptyCart from '../../../common/assets/images/empty-cart.png'
import './EmptyCart.scss';

const EmptyCart = () => {
  const navigate = useNavigate();

  return (
    <div className="empty-cart-container">
      <div className="title">Your cart is empty!</div>
      <button className="continue-btn" onClick={ () => navigate('/products/hid-origo-mobile-identities') }>continue shopping</button>
      <img src={emptyCart} className="cart-icon" />
      </div>
  )
}

export default EmptyCart