import React from 'react';
import { Grid, Divider } from '@mui/material';
import defImg from '../../common/assets/images/Rectangle_26.png'
import Iconography from '@hid-galaxy-ui/galaxy-react/components/Iconography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { orderDetailSagaAction } from '../../../redux/saga/my-account/saga-actions';
import SkeletonOrderDetails from './skeletonOrderDetails'
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import EmptyCart from './EmptyCart';
import './OrderDetails.scss'
import convertToTwoDecimalValue from '../../../common/utils/convertToTwoDecimalValue';

const OrderDetailsPage = () => {
   const location = useLocation();
   const dispatch = useAppDispatch();
   const { orderDetail, allOrders, status } = useAppSelector((state) => state.my_account);
   const { loading } = status;
   let emailId = localStorage.getItem("user-emailId");
   console.log("orderDetail", orderDetail);
   React.useEffect(() => {
      dispatch(orderDetailSagaAction(location?.state?.orderId))
   }, [location]);
   console.log("location --> order-detail", location.state)
   return (<div className="order-details-container">
      {loading ? <SkeletonOrderDetails /> : <>
         <Stack spacing={2} className="nav-info">
            <Breadcrumbs separator={<Iconography icon="angleRight" />} >
               {[
                  <h6>My Account</h6>,
                  <h6>Order History</h6>,
                  <h5>{orderDetail?.name}</h5>
               ]}
            </Breadcrumbs>
         </Stack>
         <>

            <Grid container columns={12} className="header-invoice">
               <Grid item md={8} className="header-box">
                  <div className="order-header">Order {orderDetail?.name}</div>
               </Grid>
               {/* <Grid item md={4} className="invoice-box">
                  <div className="order-invoice"><Iconography icon="arrowDown" /> Download Invoice</div>
               </Grid> */}
            </Grid>

            <Grid container columns={14} className="container-box">
               <Grid item lg={8} md={14} className="left">
                  <Grid className="head-box1">
                     <div className="table-head1">Order Details</div>
                  </Grid>

                  <Grid className="left-table">
                     <Grid container columns={14} className="desc-box">
                        <Grid item md={8} className="title-box">
                           <div className="heading1">Items Summary</div>
                        </Grid>
                        <Grid item md={3} className="title-box">
                           <div className="heading2">Qty</div>
                        </Grid>
                        <Grid item md={3} className="title-box">
                           <div className="heading3">Price</div>
                        </Grid>
                     </Grid>

                     <Divider flexItem={true} style={{ border: '1px solid #DDDDDD', marginBottom: '1.5rem' }} />

                     {orderDetail?.lineItems?.nodes?.map((element, index) => (
                        <>
                           {index === 0 ?
                              <Grid container columns={14} className="cart-box">
                                 <Grid item md={8} className="product-box">
                                    <div className="img-wrapper">
                                       <img src={
                                          process.env.REACT_APP_ORDER_AND_SERVICE_IMAGE
                                       } />
                                       {/* // orderDetail?.lineItems?.nodes[0]?.variant?.image?.url */}
                                    </div>
                                    <div className="product-info">
                                       <div className="title">{element?.title} </div>
                                    </div>
                                 </Grid>
                                 <Grid item md={3} className="price-box">
                                    <div className="price-text" >
                                       <div className="price">{element?.originalTotalPrice?.amount === '0.0' ? '' : `x${element?.quantity}`}</div>
                                    </div>
                                 </Grid>
                                 <Grid item md={3} className="price-box">
                                    <div className="price-text" >
                                       <div className="price">{element?.originalTotalPrice?.amount === '0.0' ? '' : `$${convertToTwoDecimalValue(element?.originalTotalPrice?.amount)}`}</div>
                                    </div>
                                 </Grid>
                              </Grid>
                              :
                              <Grid container columns={14} className="cart-box" style={{ marginTop: '0', marginBottom: '0', minHeight: '40px', paddingLeft: '16px' }}>
                                 <Grid item md={14} className="product-box">
                                    <div className="icon-wrapper">
                                       <Iconography icon="circleCheck" style={{ color: '#4A4A4A' }} />
                                    </div>
                                    <div className="product-info">
                                       <div className="title" style={{ fontSize: '14px' }}>{element?.title} </div>
                                    </div>
                                 </Grid>
                              </Grid>
                           }

                        </>
                     ))}
                     <Divider flexItem={true} style={{ border: '1px solid #DDDDDD', marginTop: '1.5rem' }} />

                     <Grid container columns={14} className="end-box">
                        <Grid item md={11} className="bottom-box">
                           <div className="end-text1">Total Paid Amount</div>
                        </Grid>
                        <Grid item md={3} className="bottom-box">
                           <div className="end-text2">${`${convertToTwoDecimalValue(orderDetail?.totalPrice?.amount)}`}</div>
                        </Grid>
                     </Grid>

                  </Grid>
               </Grid>

               <Grid item lg={6} md={14} className="right">
                  <Grid item md={14} className="head-box2">
                     <div className="table-head2">Customer details</div>
                  </Grid>

                  <Grid className="right-table">
                     <Grid container columns={14} className="customer-box" style={{ paddingTop: '26px' }}>
                        <Grid item md={7} className="details-box">
                           <div className="details-label">Customer Name</div>
                        </Grid>
                        <Grid item md={7} className="details-box">
                           <div className="details-value">{allOrders?.displayName}</div>
                        </Grid>
                     </Grid>
                     <Grid container columns={14} className="customer-box">
                        <Grid item md={7} className="details-box">
                           <div className="details-label">Email ID</div>
                        </Grid>
                        <Grid item md={7} className="details-box">
                           <div className="details-value">{emailId}</div>
                        </Grid>
                     </Grid>
                     {/* <Grid container columns={14} className="customer-box">
                        <Grid item md={7} className="details-box">
                           <div className="details-label">Address Line</div>
                        </Grid>
                        <Grid item md={7} className="details-box">
                           <div className="details-value"></div>
                        </Grid>
                     </Grid>
                     <Grid container columns={14} className="customer-box">
                        <Grid item md={7} className="details-box">
                           <div className="details-label">Street Name</div>
                        </Grid>
                        <Grid item md={7} className="details-box">
                           <div className="details-value"></div>
                        </Grid>
                     </Grid>
                     <Grid container columns={14} className="customer-box">
                        <Grid item md={7} className="details-box">
                           <div className="details-label">Post Code</div>
                        </Grid>
                        <Grid item md={7} className="details-box">
                           <div className="details-value"></div>
                        </Grid>
                     </Grid>
                     <Grid container columns={14} className="customer-box">
                        <Grid item md={7} className="details-box">
                           <div className="details-label">Phone number</div>
                        </Grid>
                        <Grid item md={7} className="details-box">
                           <div className="details-value"></div>
                        </Grid>
                     </Grid>
                     <Grid container columns={14} className="customer-box">
                        <Grid item md={7} className="details-box">
                           <div className="details-label">Payment mode</div>
                        </Grid>
                        <Grid item md={7} className="details-box">
                           <div className="details-value"></div>
                        </Grid>
                     </Grid> */}
                  </Grid>

                  <Grid container columns={14} className="order-box">
                     <Grid item md={8} className="status-box">
                        <div className="order-label">Order Status</div>
                     </Grid>
                     <Grid item md={6} className="status-box">
                        {/* {orderDetail?.fulfillmentStatus == "UNFULFILLED" ? <div className="order-value">Placed</div> :
                           <div className="order-value">Completed</div>} */}
                            <div className={`order-value ${orderDetail?.fulfillmentStatus === "ON HOLD" ? orderDetail?.fulfillmentStatus.split(" ").join("") :  orderDetail?.fulfillmentStatus}`}>{orderDetail?.fulfillmentStatus}</div>
                     </Grid>

                     <Grid item md={8} className="status-box">
                        <div className="order-label">Payment Status</div>
                     </Grid>
                     <Grid item md={6} className="status-box">
                        {orderDetail?.financialStatus == "PENDING" ?
                           <div className="order-value" style={{color:"#00539B"}}>{orderDetail?.financialStatus}</div>
                           :
                           <div className="order-value">{orderDetail?.financialStatus}</div>
                        }
                     </Grid>
                  </Grid>

               </Grid>
            </Grid>


         </>
      </>}

   </div>)
}

export default OrderDetailsPage;