import React from 'react';
import defImg from '../../../common/assets/images/OrderDetailImg.png';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import DevelopersApplications from './developers-applications';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Iconography from '@hid-galaxy-ui/galaxy-react/components/Iconography';
import './ServiceContracts.scss';
import moment from 'moment';

const Child: React.FC<{
    cardDetails: {
        Active: string
        ContractEndDate: string
        ContractId: string
        ContractName: string
        ContractStartDate: string
        Created: string
        LastUpdated: string
        OnboardingStageId: string
        PartnerId: string
        PartnerManagerId: string
        Status: string
    },
}> = ({ cardDetails }) => {

    const [detailsExpand, setDetailsExpand] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    //console.log("cardDetails in child", cardDetails)
    const styleModal = {
        position: 'absolute',
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '744px',
        bgcolor: 'background.paper',
        borderRadius: '5px',
    };
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleDetailsClick = () => {
        setDetailsExpand(!detailsExpand);
    }

    return (<div className="service-box-expanded">
        <div className="service-box">
            <div className="image-wrapper">
                <img src={process.env.REACT_APP_ORDER_AND_SERVICE_IMAGE} />
            </div>
            <div className="text-wrapper">
                <div className="service-name">{cardDetails?.ContractName}</div>
                <div className="details">
                    <div className="detail-wrapper">
                        <div className="org" style={{
                            // backgroundColor : 'red'
                        }}>HID</div>

                    </div>
                    <div className="detail-wrapper" style={{ display: 'flex' }}>
                        <div className="cur-status" style={{
                            // backgroundColor : 'red'
                        }}>Current Status :&nbsp;</div>
                        {/* <div className="status" style={{
                            // backgroundColor : 'red'
                        }}>Onboarding</div> */}
                        <div className={cardDetails.Status === "expired" || cardDetails.Status === "Expired" ? 'warning' : 'primary'}>{cardDetails.Status}</div>
                    </div>

                </div>
                <div className="details">
                        <div className="detail-wrapper" style={{ display: 'flex' }}>
                            <div className="cur-status" style={{
                                // backgroundColor : 'red'
                            }}>Purchased:&nbsp;</div>
                            <div className="status" style={{
                                // backgroundColor : 'red'
                            }}>{moment(cardDetails.ContractStartDate).format('DD-MM-YYYY')}</div>
                        </div>

                    
                    <div className="detail-wrapper" style={{ display: 'flex' }}>
                        <div className="cur-status" style={{
                            // backgroundColor : 'red'
                        }}>Validity:&nbsp;</div>
                        <div className="status" style={{
                            // backgroundColor : 'red'
                        }}>{moment(cardDetails.ContractEndDate).format('DD-MM-YYYY')}</div>

                    </div>
                </div>

            </div>
            {/* <button className="details-btn" onClick={handleDetailsClick}>
                Details &nbsp;{detailsExpand ? <Iconography icon='angleUp' style={{ transform: 'scale(0.9)', paddingBottom: '2px' }} /> : <Iconography icon='angleDown' style={{ transform: 'scale(0.9)', paddingBottom: '2px' }} />}
            </button> */}
        </div>
        {detailsExpand ?
            <div className="box-bottom">
                <div className="add-dev" onClick={handleOpenModal}>Add Developers/Applications</div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{ overflow: 'scroll' }}
                >
                    <Fade in={openModal}>
                        <Box sx={styleModal}>
                            <DevelopersApplications handleCloseModal={handleCloseModal} contractId={cardDetails?.ContractId} />
                        </Box>
                    </Fade>
                </Modal>
                <div className="support"><HelpOutlineRoundedIcon />&nbsp; Need support</div>
            </div>
            :
            <></>
        }
    </div>)
}


export default Child;





