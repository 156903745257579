import { call, put, takeLatest, delay, select } from "redux-saga/effects"
import { homeSagaActionType } from "./saga-actions"
import { axiosGraphqlInstance, axiosRestInstance } from "../../../common/axios"
import { homeSliceAction } from "../../slices/home"
import { AxiosResponse } from "axios"

const featuredProductApi = async (sub_package_type: string) => {
    console.log("sub_package_type", sub_package_type)
    let response = axiosGraphqlInstance.post<AxiosResponse<{}>>('/graphql.json', {
        query: ` {collection(handle: "${sub_package_type}") {
        id
        handle
        title
        description
        products(first: 4) {
          nodes{
            title
            id
            description
            priceRange{
              maxVariantPrice{
                amount
                currencyCode
              }
            }
            featuredImage{
              src
            }
          }
        }
    }}
    `,
        variables: {

        }
    }).then((response: any) => response.data.data.collection)
    return response;
}

const promotionalBannerFetchAPI = async () => {
    let response = axiosGraphqlInstance.post<AxiosResponse<{
        articles: {
            nodes: {}[]
        }

    }>>('/graphql.json', {
        query: ` {
         articles(first: 3, query: "tag:promotional") {
         nodes {
            id
          title
           content 
         image { 
         url
          }
            }
            }
          }`,
        variables: {

        }
    }).then((response) => response.data.data.articles.nodes)
    return response;
}


function* getFeaturedProductSaga(actions: {
    type: string
}) {
    try {
        let response: {} = yield call(featuredProductApi, "featured-packages")
        yield put(homeSliceAction.setFeaturedCollection(response));
        console.log("response --> featured-packages", response)
    } catch (e) {
        console.log(e)
    }
}


function* promotionalBannerFetchSaga(actions: {
    type: string
}) {
    try {
        let response: [] = yield call(promotionalBannerFetchAPI)
        yield put(homeSliceAction.setBanners(response));
        console.log("response --> promotional-banners", response)
    } catch (e) {
        console.log(e)
    }
}




function* homeSaga() {

    yield takeLatest(homeSagaActionType.callGetFeaturedProductSaga, getFeaturedProductSaga);
    yield takeLatest(homeSagaActionType.callPromotionalBannerFetchSaga, promotionalBannerFetchSaga);

}

export default homeSaga;