import { Divider, Grid, Skeleton } from "@mui/material"

const SkeletonOnLoadingPDP  = () => {
    return (
        <>
            <div className="product-info">
                <Grid container>
                    <Grid item md={4}>
                        <div className="img-wrapper">
                            <Skeleton style={{
                                height: "100%",
                                width: "100%",
                            }} variant='rectangular' />
                        </div>

                    </Grid>
                    <Grid item md={8}>
                        <div className="text-wrapper">
                            <div className="title">
                                <Skeleton style={{
                                    borderRadius: "10px",
                                    height: "56px",
                                    width: "100%",
                                }} variant='rectangular' />
                                <Skeleton style={{
                                    borderRadius: "10px",
                                    height: "56px",
                                    width: "50%",
                                    marginTop: "8px"
                                }} variant='rectangular' />
                            </div>
                            <div className="price-text">
                                <Skeleton style={{
                                    borderRadius: "10px",
                                    height: "56px",
                                    width: "10rem",
                                }} variant='rectangular' />
                            </div>
                            <div className="content">
                                {[1, 2, 3].map(() => {
                                    return (
                                        <Skeleton style={{
                                            borderRadius: "10px",
                                            height: "28px",
                                            width: "100%",
                                            marginTop: "8px",
                                        }} variant='rectangular' />
                                    )
                                })}
                            </div>
                            <div>
                                <Skeleton style={{
                                    borderRadius: "10px",
                                    height: "50px",
                                    width: "100%",
                                }} variant='rectangular' />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="product-features">
                <div className="tabs">
                    <div className={true ? "active" : "inactive"}>
                        Features
                    </div>
                    <Divider flexItem={true} orientation={'vertical'} />
                    <div className={false ? "active" : "inactive"}>
                        Case Studies
                    </div>
                </div>

                <Grid container>
                    {[1, 2, 3, 4].map(() => {

                        return (
                            <Grid item md={6}>
                                <div className="feature-grid-item">
                                    <div className="icon-wrapper">
                                        <Skeleton style={{
                                            borderRadius: "10px",
                                            height: "5rem",
                                            width: "5rem",
                                        }} variant='rectangular' />
                                    </div>
                                    <div className="feature-list">
                                        <Skeleton style={{
                                            borderRadius: "10px",
                                            height: "48px",
                                            width: "18rem",
                                        }} variant='rectangular' />
                                        {[1, 2, 3, 4].map(() => {
                                            return (
                                                <>
                                                    <div className="list-item">
                                                        <Skeleton style={{
                                                            borderRadius: "10px",
                                                            height: "28px",
                                                            width: "18rem",
                                                            marginTop: "8px",
                                                        }} variant='rectangular' />
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </>
    )
}

export default SkeletonOnLoadingPDP;