import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Button, Divider } from '@mui/material';
import './Cart.scss'
import defImg from '../../common/assets/images/Rectangle_26.png'
import Iconography from '@hid-galaxy-ui/galaxy-react/components/Iconography';
import EmptyCart from '../../pages/Cart/EmptyCart';
// import { getAllCartItemsSagaActions } from '../../redux/saga/showCartItems/saga-action';
// import { getDeleteCartItemSagaActions } from '../../redux/saga/deleteCartItem/saga-actions';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from '../../redux';
import { deleteCartSagaActions, fetchCartSagaActions } from '../../redux/saga/cart/saga-actions';
import SkeletonCart from './SkeletonCart';
import { useNavigate } from 'react-router';
import convertToTwoDecimalValue from '../../common/utils/convertToTwoDecimalValue';


const Cart = () => {

    // const { allCartItems } = useAppSelector((state) => state.cartItems);
    // const dispatch = useAppDispatch();
    // const [countCartItem, setcountCartItem] = useState(0)
    const [clickCheckout, setClickCheckout] = useState(false);
    const { cartItems, cartSize, isLoading, checkoutURL } = useAppSelector((state) => state.cart);
    console.log("{ cartItems, cartSize, isLoading, checkoutURL }", { cartItems, cartSize, isLoading, checkoutURL })
    const cardId: string | null = localStorage.getItem('cartId')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (cardId && cartSize === 0) {
            dispatch(fetchCartSagaActions(cardId))
        }
    }, []);

    // useEffect(() => {
    //     dispatch(getAllCartItemsSagaActions({}))
    //     console.log('from useEffect llCartItems', allCartItems)
    // }, [allCartItems])

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
        setClickCheckout(false);
    }, [])

    // React.useEffect(() => {
    //     window.scrollTo({
    //         top: 0
    //     })
    //     setTimeout(() => {
    //         const countItems = allCartItems.cart.lines.edges.filter((countArry: any) => {
    //             return countArry.node.merchandise.product.productType == "Services"
    //         })
    //         setcountCartItem(() => countItems.length);
    //         // console.log('countItems.length', countItems.length)
    //     }, 2000)
    // }, [countCartItem]);

    return (

        <div className="cart-container">
            <div className="cart-name">My Cart</div>
            {isLoading ? <SkeletonCart />
                : <>
                    {cartSize === 0 || cartSize === undefined ?
                        <EmptyCart /> :
                        <>
                            <div className="cart-header">Shopping Cart ({cartSize} Item)</div>
                            <Grid container columns={14}>
                                <Grid item md={9} className="view-left">

                                    {cartItems?.lines?.edges?.map((item: any, index: number) => {
                                        const objUrl: any = []
                                        // item.node.merchandise.product.id
                                        // allCartItems.cart.lines.edges.filter((items:any)=> {
                                        //     return item.node.merchandise.product.id  
                                        // })
                                        // console.log('=====item for product ID', item)
                                        return (
                                            //   {item === 0 ? <></> : <Divider flexItem={true} style={{ border: '1px solid #DDDDDD' }} />}
                                            <>

                                                {item.node.merchandise.product.productType == "Services" ?
                                                    <>
                                                        {index === 0 ? <></> : <Divider flexItem={true} style={{ border: '1px solid #DDDDDD' }} />}
                                                        <Grid container columns={14} className="cart-box">
                                                            <Grid item md={10} className="product-box">
                                                                <div className="img-wrapper" onClick={() => {
                                                                    navigate('/product-detail', {
                                                                        state: {
                                                                            productID: item.node?.merchandise?.product?.id
                                                                        }
                                                                    });
                                                                }}>
                                                                    <img src={item.node.merchandise.product.featuredImage.url}></img>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="title" onClick={() => {
                                                                        navigate('/product-detail', {
                                                                            state: {
                                                                                productID: item.node?.merchandise?.product?.id
                                                                            }
                                                                        });
                                                                    }}>{item.node?.merchandise?.product?.title}</div>
                                                                    <div className="list">
                                                                        {
                                                                            item.node.attributes.map((attr: any) => {
                                                                                cartItems.lines.edges.map((lineItem: any) => {
                                                                                    if (lineItem.node.merchandise.product.productType !== "Services") {
                                                                                        let compTypeUrl = `gid://shopify/ProductVariant/` + attr.key.slice(1,)
                                                                                        if (compTypeUrl === lineItem.node.merchandise.id) {
                                                                                            // console.log('=====> node ID ',lineItem.node.id)
                                                                                            objUrl.push(lineItem.node.id)
                                                                                        }
                                                                                    }
                                                                                })
                                                                                return (<div className="list-item"><Iconography icon="circleCheck" style={{ color: '#4A4A4A' }} />&nbsp; {attr.value}</div>)
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item md={4} className="price-box">
                                                                <div className="price-text" >
                                                                    <div className="price">${convertToTwoDecimalValue(item?.node?.merchandise?.price?.amount)}</div>
                                                                    {/* <div className="per-text">&nbsp; /{item.node?.merchandise?.product?.metafield?.value}</div> */}
                                                                </div>
                                                                {/* <div className="price-text">Save $2.00</div> */}
                                                                <div className="btn-box">
                                                                    {/* <div className="quantity">
                                        <div className="dec-btn"><Iconography icon='minus' style={{ transform: 'scale(0.7)' }} /></div>
                                        1
                                        <div className="inc-btn"><Iconography icon='plus' style={{ transform: 'scale(0.7)' }} /></div>
                                    </div> */}
                                                                    <Button
                                                                        startIcon={<Iconography icon="trashCan" />}
                                                                        color="error"
                                                                        className="delete-btn"
                                                                        onClick={() => {
                                                                            // dispatch(getDeleteCartItemSagaActions({
                                                                            //     objectUrl: objUrl,
                                                                            //     mainProductId: item.node.merchandise.product.id,
                                                                            //     mainProductURl: item.node.id
                                                                            // }))

                                                                            // setTimeout(() => {
                                                                            //     dispatch(getAllCartItemsSagaActions({}))
                                                                            // }, 1000)
                                                                            dispatch(deleteCartSagaActions({
                                                                                attributesIdArray: objUrl,
                                                                                productCartLineId: item.node.id,
                                                                                productVariantId: item.node.merchandise.product.id
                                                                            }))
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                    :
                                                    <></>}
                                            </>
                                        )
                                    })
                                    }


                                </Grid>
                                <Grid item md={5} className="view-right">
                                    <div className="checkout-box">
                                        {/* <div className="promo-label">
                                Have a promo code?
                            </div>
                            <div className="coupon">
                                <input
                                    type="text"
                                    className="input-box"
                                />
                                <button className="apply-btn">Apply</button>
                            </div> */}
                                        <div className="text-1">Order Summary</div>
                                        <div className="text-2">Subtotal</div>
                                        <div className="text-1">${convertToTwoDecimalValue(cartItems?.cost?.subtotalAmount?.amount)}</div>
                                        {/* <div className="text-2">Estimated Charges</div>
                            <div className="text-1">$0</div> */}
                                        <Divider flexItem={true} style={{ border: '1px solid #DDDDDD', margin: '1rem 0' }} />
                                        <div className="text-2">Grand Total</div>
                                        <div className="text-1">${convertToTwoDecimalValue(cartItems?.cost?.totalAmount?.amount)}</div>
                                        <button className="checkout-btn"
                                            onClick={() => {
                                                setClickCheckout(true);
                                                window.open(checkoutURL, "_self");
                                            }}>
                                            Check Out &nbsp;{clickCheckout ? <CircularProgress color="inherit" size={24} /> : <></>}
                                        </button>
                                    </div>

                                </Grid>
                            </Grid>
                        </>
                    }

                </>}
        </div>
    )
}

export default Cart;


