import React from 'react';
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import LandingPage from "./pages"
import ProductsListPage from "./pages/product-list";
import ProductsDetailsPage from "./pages/product-detail";
import Cart from './pages/Cart';
import Login from './pages/login';
import useCheckAuthentication from "./hooks/useCheckAuthentication";
import OrderDetailsPage from './pages/my-account/order-details';
import MyAccount from './pages/my-account';
import OrderHistory from './pages/my-account/order-history';
import ServiceContracts from './pages/my-account/service-contracts';
import FaqPage from './pages/faq';
import useProductListPageRoute from './hooks/useProductListPageRoute';

const App: React.FC<{}> = (props) => {
  
  const [authenticationStatus, authenticationConsoleArray] = useCheckAuthentication();
  const {productListRoutesArray} = useProductListPageRoute();
  console.log('useCheckAuthentication --console', authenticationConsoleArray);
  return (
    <div className="App main-contant-wrapper">
      <Routes>
        {authenticationStatus ? 
        <Route path="/" element={<LandingPage/>}>
          <Route path="products" element={<Outlet />} >
            {productListRoutesArray.map((data) => {
                return <Route path={data.route} element={<ProductsListPage />} />
            })}
          </Route>
          <Route path="product-detail" element={<ProductsDetailsPage />} />
          <Route path="order-details" element={<OrderDetailsPage />} />
          <Route path="my-account" element={<MyAccount />} >
            <Route  path="order-history"  element={<OrderHistory />} />
            <Route path="service-contracts" element={<ServiceContracts />} />
          </Route>
          <Route path="cart" element={<Cart />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="*" element={<Navigate to='/' />} />

        </Route> :
          <Route path="/login" element={<Login />} />}
      </Routes>
    </div>
  );

}

export default App;



