import { call, delay, put, select } from "redux-saga/effects"
import { fetchCartSagaActions } from "../saga-actions"
import { axiosGraphqlInstance } from "../../../../common/axios"
import { cartSliceAction } from '../../../../redux/slices/cart'
import { AxiosResponse } from "axios"
import { useNavigate } from "react-router"
import { RootStateType } from "../../.."


type createCartApiResponseType = {
  "data": {
    "cartCreate": {
      "cart": {
        "id": string,
        "checkoutUrl": string,
        "buyerIdentity": {
          "customer": {
            "email": string
          }
        },
        "lines": {
          "edges":
          {
            "node": {
              "id": string
            }
          }[]
        }
      },
      "userErrors": []
    }
  }
}

const createCartAPI = (data: {
  lines: string[],
  userToken: string,
}) => {
  console.log("createCartAPI --> data", data?.lines.join(','),)
  let response = axiosGraphqlInstance.post<AxiosResponse<createCartApiResponseType>>('/graphql.json', {
    query: `mutation {
            cartCreate(input: {lines: [${data?.lines.join(',')}] , 
            buyerIdentity: {customerAccessToken: "${data?.userToken}"}}) {
              cart {
                id
                checkoutUrl
                buyerIdentity {
                  customer {
                    email
                  }
                }
                lines(first: 10) {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              userErrors {
                        code
                        field
                        message
                        }
            }
          }
        `,
    variables: {
    }
  })
    .then((response) => response.data)
    .catch((error) => error)

  return response
}
// type updateCartApiResponseType = {

// }
// const updateCartAPI = (data: {
//   cartId: string,
//   lines: string,
// }) => {
//   // console.log("updateCartAPI --> data", data?.lines.join(','))
//   let response = axiosGraphqlInstance.post<AxiosResponse<updateCartApiResponseType>>('/graphql.json', {
//     query: `mutation { 
//             cartLinesUpdate(cartId: "gid://shopify/Cart/${data?.cartId}", 
//             lines: ${data?.lines}, 
//           ) { 
//               cart { 
//                 id 
//                 checkoutUrl 
//                 lines(first: 10) { 
//                   edges { 
//                     node { 
//                       id 
//                       quantity 
//                       attributes { 
//                         key 
//                         value 
//                       } 
//                       merchandise { 
//                         ... on ProductVariant { 
//                           id 
//                           product { 
//                             id 
//                             title 
//                             productType 
//                             metafield(namespace: "custom", key: "validation_date") { 
//                               value 
//                             } 
//                             featuredImage { 
//                               url 
//                             } 
//                           } 
//                         } 
//                       } 
//                     } 
//                   } 
          
//                 } 
          
//                 buyerIdentity { 
//                   customer { 
//                     id 
//                     email 
//                     phone 
//                   } 
//                 } 
//               } 
//               userErrors { 
//                 field 
//                 message 
//               } 
//             } 
//           }
//             `,
//     variables: {
//     }
//   })
//     .then((response) => response.data)
//     .catch((error) => error)

//   return response
// }

type addToCartApiResponseType = {

}
const addToCartAPI = (data: {
  cartId: string,
  lines: string[],
}) => {
  // lines: [${data?.lines.join(',')}],
  console.log("addToCartAPI --> data", data?.lines)
  let response = axiosGraphqlInstance.post<AxiosResponse<addToCartApiResponseType>>('/graphql.json', {
    query: `mutation { 
            cartLinesAdd(cartId: "gid://shopify/Cart/${data?.cartId}", 
            lines: [${data?.lines.join(',')}], 
          ) { 
              cart { 
                id 
                checkoutUrl 
                lines(first: 10) { 
                  edges { 
                    node { 
                      id 
                      quantity 
                      attributes { 
                        key 
                        value 
                      } 
                      merchandise { 
                        ... on ProductVariant { 
                          id 
                          product { 
                            id 
                            title 
                            productType 
                            metafield(namespace: "custom", key: "validation_date") { 
                              value 
                            } 
                            featuredImage { 
                              url 
                            } 
                          } 
                        } 
                      } 
                    } 
                  } 
          
                } 
          
                buyerIdentity { 
                  customer { 
                    id 
                    email 
                    phone 
                  } 
                } 
              } 
              userErrors { 
                field 
                message 
              } 
            } 
          }
            `,
    variables: {
    }
  })
    .then((response) => response.data)
    .catch((error) => error)
  console.log("see this response ---> cartAddLine", response)
  return response
}

type cartSagaActionPayloadType = {
  pdp: {
    productVariantId: string,
    featureSectionTitleAndVariantIdArray: { variantId: string, title: string }[]
  },                      // as same as action payload type!!
  cartId: string | null,
  userToken: string | null,
  navigateFunction : () => void
}

function* cartCreateOrUpdateCart(actions: {
  type: string,
  payload: cartSagaActionPayloadType
}) {
  console.log("cartCreateOrUpdateCart -> actions", actions)
  try {
    yield put(cartSliceAction.setIsLoading(true));
    const attributes: { key: string, value: string }[] = actions?.payload?.pdp?.featureSectionTitleAndVariantIdArray?.map((element) => {
      return {
        key: element?.variantId?.split('/')[element?.variantId?.split('/').length - 1],
        value: element?.title
      }
    });
    const objectToStringConverterForLines = ({ quantity, merchandiseId, attributes }: { quantity: number, merchandiseId: string, attributes: string | null }) => {
      if (attributes === null) {
        return `{
                quantity : ${quantity},
                merchandiseId : "${merchandiseId}"
            }`
      } else {
        return `{
                quantity : ${quantity},
                merchandiseId : "${merchandiseId}",
                attributes : [${attributes}]
            }`
      }

    }
    // const objectToStringConverterForLinesUpdate = ({quantity, CartLineID, attributes} : {quantity : number, CartLineID : string, attributes : string | null}) => {

    //     return `{
    //         id : "${CartLineID}"
    //         quantity : ${quantity},
    //         attributes : [${attributes}]
    //     }`


    // }
    const objectToStringConverterForAttributes = ({ key, value }: { key: string, value: string }) => {
      return `{
                key: "${key}",
                value: "${value}"
            }`
    }
    const lines: string[] = [
      objectToStringConverterForLines({
        quantity: 1,
        merchandiseId: `${actions?.payload?.pdp?.productVariantId}`,
        attributes: attributes.map((element) => objectToStringConverterForAttributes({
          key: "_"+element.key,
          value: element.value,
        })).join(',')
      })
    ]
    attributes.forEach((element) => {
      lines.push(objectToStringConverterForLines({ quantity: 1, merchandiseId: `gid://shopify/ProductVariant/${element?.key}`, attributes: null }))
    })

    //  const lineForAddingProduct = objectToStringConverterForLines({
    //     quantity : 1,
    //     merchandiseId : `${actions?.payload?.pdp?.productVariantId}`,
    //     attributes : null
    // })

    if (actions?.payload?.cartId) {   // update existing cart - API call

      let responseAdd: addToCartApiResponseType = yield call(addToCartAPI, {
        cartId: actions?.payload?.cartId,
        lines: lines
      });
      // yield delay(400)
      // let response: updateCartApiResponseType = yield call(updateCartAPI, {
      //     cartId: actions?.payload?.cartId,
      //     lines: objectToStringConverterForLinesUpdate({
      //         quantity : 1,
      //         CartLineID : ``,
      //         attributes : attributes.map((element) => objectToStringConverterForAttributes({
      //             key : element.key,
      //             value : element.value,
      //         })).join(',')
      //     })
      // });
      yield put(fetchCartSagaActions(actions?.payload?.cartId));
      let {} : {} = yield select((state: RootStateType) => state.cart );
      yield call(actions?.payload?.navigateFunction);
      console.log("response --> update/cart", responseAdd)
    } else {   // create new cart - API call!!
      let response: createCartApiResponseType = yield call(createCartAPI, {
        userToken: actions?.payload?.userToken,
        lines: lines
      });
      let cartFullIdSplited = response?.data?.cartCreate?.cart?.id?.split('/')
      let cartId = cartFullIdSplited[cartFullIdSplited.length - 1]
      yield put(fetchCartSagaActions(cartId));
      localStorage.setItem('cartId', cartId)
      yield call(actions?.payload?.navigateFunction);
      console.log("response --> create/cart", response)
    }
    yield put(cartSliceAction.setIsLoading(false));

  } catch (e) {
    yield put(cartSliceAction.setIsLoading(false));
    yield put(cartSliceAction.errorInfoTrigger({
      severity: "error",
      errorMsg: "Technical Error!",
      open: true
    }))
  }
}

export default cartCreateOrUpdateCart;