import React, { useState } from 'react';
import Card from "@hid-galaxy-ui/galaxy-react/components/Card";
import Button from "@hid-galaxy-ui/galaxy-react/components/Button";
import "@hid-galaxy-ui/galaxy-css/galaxy.css";
import './recommendedProductCard.scss';
import defImg from '../../assets/images/hid_logo.png'
import { useNavigate, useParams, useLocation } from 'react-router';


// if you will use mock? as true then card component will return an element which will be for design purpose only
const RecommendedProductCard = ({ product, onClick, mock }: {
    product?: any,
    onClick?: () => void,
    mock?: boolean
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [imgSrc, setImgSrc] = useState<string>( product?.featuredImage?.url ? product?.featuredImage?.url : "https://cdn.shopify.com/s/files/1/0707/1468/7765/products/Rectangle7_ab0f2457-60f0-40e0-b098-362e99b633e2.png?v=1674815432");
    
    const navigateToPDP = () => {
        navigate('/product-detail', {
            state : {
                productID : product?.id
            }
        });
    }

    // console.log("productCard product-prop", product)
    return mock ? (
        //! this return is just a mock for design purpose!!
        <div className="r-product-card">
            <Card >
                <>
                    <div className="img-wrapper">
                        <img
                            src={defImg}
                            alt={"Image Not Found!"}
                        // onError = {() => setImgSrc(defImg)}
                        />
                    </div>
                    <div className="title" onClick={() => console.log("go to PDP page")}>{"Title"}</div>
                    <div className="card-bottom">
                        <div className="price-head">Price Per Year</div>
                        <div className="price">$122</div>
                        <Button label="Add To Cart" variant="primary" normalCase={true}
                            style={{ margin: "1rem", background: "#00539B" }}
                            onClick={() => console.log("button click")} />
                    </div>
                </>
            </Card>
        </div>
    ) : (
        // this return will be mapped with api call
        <div className="r-product-card">
            <Card>
                <>
                    <div className="img-wrapper">
                        <img
                            src={imgSrc}
                            alt={product?.title}
                            onError={() => setImgSrc(defImg)}
                        />
                    </div>
                    <div className="title" onClick={navigateToPDP}>
                        {product?.title}
                    </div>
                    <div className="card-bottom">
                        <div className="price-head">Price Per Year</div>
                        <div className="price">${product.priceRange.maxVariantPrice?.amount}</div>
                        {location.pathname === "/product-detail" ? 
                        <Button label="Add To Cart" variant="primary" normalCase={true}
                            style={{ margin: "1rem", background: "#00539B" }}
                            // onClick={navigateToPDP} 
                            /> : 
                            <></>} 
                    </div>
                </>
            </Card>
        </div>
    )
    return
}


export default RecommendedProductCard;