import { call, put, takeLatest, delay, select } from "redux-saga/effects"
import { getAppsForContractSagaAction, getDevelopersForContractSagaAction, myAccountSagaActionType } from "./saga-actions"
import { axiosGraphqlInstance, axiosRestInstance } from "../../../common/axios"
import { myAccountSliceAction } from "../../slices/my-account"
import { AxiosResponse } from "axios"

type contractFindAllAPIResponceType = {
    Active: string
    ContractEndDate: string
    ContractId: string
    ContractName: string
    ContractStartDate: string
    Created: string
    LastUpdated: string
    OnboardingStageId: string
    PartnerId: string
    PartnerManagerId: string
    Status: string   // chnaged for Status: string date 14/04/2023 by yash
}[];

type createContractAPIResponceType = {
  Active: string
  ContractEndDate: string
  ContractId: string
  ContractName: string
  ContractStartDate: string
  Created: string
  LastUpdated: string
  OnboardingStageId: string
  PartnerId: string
  PartnerManagerId: string
}[];

const contractFindAllAPI = () => {
    let user_emailId = localStorage.getItem("user-emailId");
    let response = axiosRestInstance.get<AxiosResponse<contractFindAllAPIResponceType>>(`/users/getcontractsforpartner/${user_emailId}`)
        .then((res) => res.data);
    return response;
}

type getDevelopersForContractAPIResponceType = {
  UserId: string
  UserName: string
  UserTypeId: string
  UserCompany: string
  UserAddress: string
  UserEMAILId: string
  Created: string
  LastUpdated: string
  Active: string
  ContractId: string
  DeveloperId: string
}[];
const getDevelopersForContractAPI = (contractId: any) => {
  let response = axiosRestInstance.get<AxiosResponse<getDevelopersForContractAPIResponceType>>('/users/getdevelopersforcontract/'+ contractId)
      .then((res) => res.data);
  return response;
}

type addDevelopersToContractAPIResponceType = {
  result: string
};
const addDevelopersToContractAPI = (Developer: any) => {
  let response = axiosRestInstance.post<AxiosResponse<addDevelopersToContractAPIResponceType>>('/users/addDevelopertoContract', Developer)
      .then((res) => res.data);
  return response;
}

type getAppsForContractAPIResponseType = {
    ApplicationId: string,
    ApplicationName: string,
    ValidationsRequested: string,
    ValidationId: string,
    Created: string,
    LastUpdated: string,
    Active: string
}[];
const getAppsForContractAPI = (contractId: any) => {
  let response = axiosRestInstance.get<AxiosResponse<getAppsForContractAPIResponseType>>('/apps/find/'+ contractId)
      .then((res) => res.data);
  return response;
}

type addAppsToContractAPIResponseType = {
  status: string
  result: string
};
const addAppsToContractAPI = (Application: any) => {
  let response = axiosRestInstance.post<AxiosResponse<addAppsToContractAPIResponseType>>('/apps/create', Application)
      .then((res) => res.data);
  return response;
}


const createContractAPI = (orderId: string) => {
  console.log("orderIdorderIdorderId ",orderId)
  let response = axiosRestInstance.get<AxiosResponse<createContractAPIResponceType>>('/contracts/createContract/'+orderId)
      .then((res) => res.data);
  return response;
}

type allOrdersAPIResponceType = {
  "data": {
      "customer": {
          "id": string,
          "displayName": string,
          "orders": {
              "edges":{
                      "node": {
                          "id": string,
                          "name": string,
                          "orderNumber": number,
                          "processedAt": string,
                          "lineItems": {
                            "nodes":{
                                    "variant": {
                                        "image": {
                                            "url": string | null
                                        }
                                    }
                                }[],
                        }
                          "totalPrice": {
                              "amount": string
                          }
                      }
                  }[]
          }
      }
  }
}

const allOrdersFetchAPI = async (customerAccessToken: string) => {
    let response = axiosGraphqlInstance.post<AxiosResponse<allOrdersAPIResponceType>>('/graphql.json', {
        query: ` {
            customer(customerAccessToken: "${customerAccessToken}") {
              id
              displayName
              orders(first: 100) {
                edges {
                  node {
                    id
                    name
                    orderNumber
                    processedAt
                    lineItems(first: 100) {
                      nodes {
                        variant {
                          image {
                            url
                          }
                        }
                      }
                    }
                    totalPrice {
                        amount
                      }            
                  }
                }
              }
            }
          }
          `,
        variables: {

        }
    }).then((response) => response?.data)
    return response;
}
 type orderDetailAPIResponce = {
  "data": {
      "node": {
          "id": string | null,
          "name": string | null,
          "shippingAddress": {
              "firstName": string | null,
              "lastName": string | null,
              "address1": string | null,
              "address2": string | null,
              "zip": string | null,
              "phone": string | null
          },
          "orderNumber": number,
          "fulfillmentStatus": string | null,
          "financialStatus": string | null,
          "totalPrice": {
              "amount": string | null
          },
          "lineItems": {
              "nodes": 
                  {
                      "title": string | null,
                      "quantity": number,
                      "variant": {
                          "image": string | null
                      },
                      "originalTotalPrice": {
                          "amount": string | null,
                          "currencyCode": string | null
                      }
                  }[],
          }
      }
  }
}
const orderDetailAPI = async (orderId: string) => {
    let response = axiosGraphqlInstance.post<AxiosResponse<orderDetailAPIResponce>>('/graphql.json', {
        query: ` {
            node(id: "${orderId}") {
              id
              ... on Order {
                id
                name
                shippingAddress {
                  firstName
                  lastName
                  address1
                  address2
                  zip
                  phone
                }
                orderNumber
                fulfillmentStatus
                financialStatus
                totalPrice {
                  amount
                }
                lineItems(first: 100) {
                  nodes {
                    title
                    quantity
                    variant {
                      image {
                        url
                      }
                    }
                    originalTotalPrice {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
          
          `,
        variables: {

        }
    }).then((response) => response.data)
    return response;
}


function* contractFindAllSaga(actions: {
    type: string
}) {
    try {
        yield put(myAccountSliceAction.setContractsLoadingStatus(true));
        let response: contractFindAllAPIResponceType = yield call(contractFindAllAPI);
        yield put(myAccountSliceAction.setContracts(response));
        console.log("response --> contracts", response)
        yield put(myAccountSliceAction.setContractsLoadingStatus(false));
    } catch (e) {
      yield put(myAccountSliceAction.setContractsLoadingStatus(false));
        console.log(e)
    }
}

function* getDevelopersForContractSaga(actions: {
  type: string,
  payload: {
    contractId: string
  }
}) {
  try {
      let response: getDevelopersForContractAPIResponceType = yield call(getDevelopersForContractAPI,actions.payload.contractId);
      yield put(myAccountSliceAction.setDevelopers(response.reverse()));
      console.log("response --> developers", response)
  } catch (e) {
      console.log(e)
  }
}
type developerPayloadType = {
  UserId: string,
  UserName: string,
  UserTypeId: string,
  UserCompany: string,
  UserAddress: string,
  UserEMAILId: string,
  Active: string,
  ContractId: string,
}
function* addDeveloperToContractSaga(actions: {
  type: string,
  payload: {
    Developer: developerPayloadType
  }
}) {
  try {
      console.log(actions.payload.Developer)
      let response: addDevelopersToContractAPIResponceType = yield call(addDevelopersToContractAPI, actions.payload.Developer);
      console.log("response --> adddeveloper", response)
      yield put(getDevelopersForContractSagaAction(actions.payload.Developer.ContractId))
  } catch (e) {
      console.log(e)
  }
}

function* getAppsForContractSaga(actions: {
  type: string,
  payload: {
    contractId: string
  }
}) {
  try {
      let response: getAppsForContractAPIResponseType = yield call(getAppsForContractAPI,actions.payload.contractId);
      yield put(myAccountSliceAction.setApplications(response.reverse()));
      console.log("response --> apps", response)
  } catch (e) {
      console.log(e)
  }
}

type applicationPayloadType = {
  ApplicationId: string
  ApplicationName: string
  Active: string
}
function* addAppsToContractSaga(actions: {
  type: string,
  payload: {
    Application: applicationPayloadType
  }
}) {
  try {
      console.log(actions.payload.Application)
      let response: addAppsToContractAPIResponseType = yield call(addAppsToContractAPI, actions.payload.Application);
      console.log("response --> addapp", response)
      yield put(getAppsForContractSagaAction(actions.payload.Application.ApplicationId))
  } catch (e) {
      console.log(e)
  }
}

function* allOrdersSaga(actions: {
    type: string
}) {
    try {
        let customerAccessToken = localStorage.getItem('user-token');
        yield put(myAccountSliceAction.setOrderHistoryLoadingStatus(true));
        let response: allOrdersAPIResponceType = yield call(allOrdersFetchAPI, customerAccessToken)
        yield put(myAccountSliceAction.setAllOrders(response?.data?.customer));
        console.log("response --> allOrders", response)
        yield put(myAccountSliceAction.setOrderHistoryLoadingStatus(false));
    } catch (e) {
        yield put(myAccountSliceAction.setOrderHistoryLoadingStatus(false));
        console.log(e)
    }
}

function* createContractSaga(actions: {
  type: string
  payload: {
    orderId: string
}
}) {
  try {
      let customerAccessToken = localStorage.getItem('user-token');
      console.log("Okkk///////// ",actions.payload.orderId)
      let response: createContractAPIResponceType = yield call(createContractAPI, actions.payload.orderId);
      localStorage.removeItem("cartItem-token")
      localStorage.removeItem("checkout-redirect-url")
      
  } catch (e) {
      console.log(e)
  }
}

type sortedLineitemNodeType =  {
  title: string;
  quantity: number;
  variant: {
      image: string;
  };
  originalTotalPrice: {
      amount: string;
      currencyCode: string;
  };
}[]


const arraySorter = (main_array : sortedLineitemNodeType) => {
  let sortedArray : sortedLineitemNodeType = []
  main_array?.forEach((element , index) => {
      let ammount : string = element?.originalTotalPrice?.amount;
    if(ammount == "0.0"){
       sortedArray?.push(element);
    }else{
       sortedArray?.unshift(element);
    }
  })
  return sortedArray
}

function* orderDetailSaga(actions: {
    type: string,
    payload: {
        orderId: string
    }
}) {
    try {
       const getCustomFulfillmentStatus : (data : string) => string = (data) => {
          switch (data) {
            case "UNFULFILLED":
                 return "Placed"

            case "ON_HOLD":
                return "ON HOLD"
          
            default:
              return "Completed"
          }  

       }
        yield put(myAccountSliceAction.setLoadingStatus(true));
        let response : orderDetailAPIResponce = yield call(orderDetailAPI, actions.payload.orderId);
        let sortedArray : sortedLineitemNodeType = yield call(arraySorter, response?.data?.node?.lineItems?.nodes);
        let sortedResponce : orderDetailAPIResponce = {data: {node : { ...response?.data?.node,fulfillmentStatus : getCustomFulfillmentStatus(response?.data?.node?.fulfillmentStatus),  lineItems : {nodes : [...sortedArray]}  }}};
        yield put(myAccountSliceAction.setOrderDetail(sortedResponce?.data?.node));
          console.log("response --> orderDetail", sortedResponce)
        yield put(myAccountSliceAction.setLoadingStatus(false));
       } catch (e) {
        console.log(e);
        yield put(myAccountSliceAction.setLoadingStatus(false));
    }
  }


function* myAccountSaga() {

    yield takeLatest(myAccountSagaActionType.callContractFindAllSaga, contractFindAllSaga);
    yield takeLatest(myAccountSagaActionType.callAllOrdersSaga, allOrdersSaga);
    yield takeLatest(myAccountSagaActionType.callOrderDetailSaga, orderDetailSaga);
    yield takeLatest(myAccountSagaActionType.callGetDevelopersForContractSaga, getDevelopersForContractSaga);
    yield takeLatest(myAccountSagaActionType.callAddDeveloperToContractSaga, addDeveloperToContractSaga);
    yield takeLatest(myAccountSagaActionType.callCreateContractSaga, createContractSaga);
    yield takeLatest(myAccountSagaActionType.callGetAppsForContractSaga, getAppsForContractSaga);
    yield takeLatest(myAccountSagaActionType.callAddAppsToContractSaga, addAppsToContractSaga)

}

export default myAccountSaga;