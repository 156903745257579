import React from 'react';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import HIDFooter from '../HIDFooter/HIDFooter';
import { ReactComponent as FacebookIcon } from '../../assets/icons/Facebook.svg';
import { ReactComponent as InstagramIcon} from '../../assets/icons/Insta.svg';
import { ReactComponent as YouTubeIcon} from '../../assets/icons/Youtube.svg';
import { ReactComponent as LinkedInIcon} from '../../assets/icons/Linkedin.svg';
import "./Footer.scss";
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer-container">

            <div className="body">

                <Container className="body-container">
                    <Stack spacing={4}>

                        <Grid container spacing={2} columns={{ xs: 12, md: 14, lg: 16 }}>
                            <Grid item xs={9} md={6} lg={8} className='grid-text'>
                                <Typography>
                                    {process.env.REACT_APP_FOOTER_FIRST_LEFT_COLUMN}
                                    &nbsp;<span style={{fontWeight:'700'}}>{process.env.REACT_APP_SUPPORT_EMAIL}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={1} lg={1} className="grid-list">
                                {/* <Stack spacing={3}>
                                    <Typography variant="h6" component="h6">
                                        Legal
                                    </Typography>
                                    <div>
                                        <Typography component="a" href="https://www.hidglobal.com/about/terms-of-use" target={'_blank'}>
                                            Terms & Conditions
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography component="a" href="https://www.hidglobal.com/about/privacy" target={'_blank'}>
                                            Privacy Policy
                                        </Typography>
                                    </div>
                                </Stack> */}
                            </Grid>
                            <Grid item xs={6} md={2} lg={2} className="grid-list">
                                {/* <Stack spacing={2}>
                                    <Typography variant="h6" component="h6">
                                        Products
                                    </Typography>
                                    <div>
                                        <Typography component="a" href="#">
                                            Packages
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography component="a" href="/products/apple-wallet">
                                            Apple Wallet
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography component="a" href="#">
                                            Misc.
                                        </Typography>
                                    </div>
                                </Stack> */}
                            </Grid>
                            <Grid item xs={6} md={2} lg={2} className="grid-list">
                                <Stack spacing={2}>
                                    <Typography component="h6">
                                        About
                                    </Typography>
                                    <div>
                                        <NavLink to="faq">
                                            FAQ
                                        </NavLink>
                                    </div>
                                    <div>
                                        <Typography component="a" href="mailto:OrigoTechPartners@hidglobal.com">
                                            Contact Us
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography component="a" href="https://www.hidglobal.com" target={'_blank'}>
                                            HID Global Website
                                        </Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            
                            <Grid item xs={6} md={3} lg={3} className="grid-list">
                                <Stack spacing={2}>
                                    <Typography variant="h6" component="h6">
                                        Social Media
                                    </Typography>
                                    <Stack direction="row" spacing={2} style={{marginTop: '26px'}}>
                                        <Link href={process.env.REACT_APP_FACEBOOK} target={'_blank'}><FacebookIcon /></Link>
                                        <Link href={process.env.REACT_APP_INSTAGRAM} target={'_blank'}><InstagramIcon /></Link>
                                        <Link href={process.env.REACT_APP_YOUTUBE} target={'_blank'}><YouTubeIcon /></Link>
                                        <Link href={process.env.REACT_APP_LINKEDIN} target={'_blank'}><LinkedInIcon /></Link>
                                    </Stack>

                                </Stack>
                            </Grid>

                        </Grid>


                    </Stack>
                </Container>

            </div>
            <div className='title'>
                <HIDFooter />
            </div>
        </div>
    )
};

export default Footer;