import React from 'react';
import { useAppDispatch } from "../redux"
import { getCollectionAction } from "../redux/saga/product-list/saga-action"

//! IMPORTANT
//TODO: Please add the --route here this will be the array of string && please put string in the proper index!
// const routeNamesArray: string[] = [
//     'hid-origo-mobile-identities',
//     'hid-signo-25b-biometric-api',
// ]
const routeNamesArray: string[] = process.env.REACT_APP_PLP_MENU_ITEMS_PIPE_SEPERATED.split('|');
console.log("process.env.REACT_APP_PLP_MENU_ITEMS_PIPE_SEPERATED.split('|')", process.env.REACT_APP_PLP_MENU_ITEMS_PIPE_SEPERATED.split('|'))
//! -^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-


type productListRoutesArrayType = {
    route: string,
    title: string,
}[];

type dispatchItFunctionType = (commingRoute: string) => void;
type getRouteIndexFunctionType = (commingRoute: string) => number;
type getTitleFunctionType = (commingRoute: string) => string;


type useProductListPageRouteType<T, T2, T3, T4> = () => {
    productListRoutesArray: T,
    getRouteIndex: T2,
    getTitle: T3,
    dispatchIt: T4,
};

const useProductListPageRoute: useProductListPageRouteType<productListRoutesArrayType, getRouteIndexFunctionType, getTitleFunctionType, dispatchItFunctionType> = () => {

    const dispatch = useAppDispatch();

    const productListRoutesArray: productListRoutesArrayType = routeNamesArray.map((parentElement) => {
        console.log("parentElement", parentElement)
        return {
            route: parentElement,
            title: parentElement.split('-').map((element, index) => {
                return element.split('').map((subElelment, subIndex) => {
                    return subIndex === 0 ? subElelment.toLocaleUpperCase() : subElelment
                }).join('')
            }).join(" ")
        }
    })

    const getRouteIndex: getRouteIndexFunctionType = (commingRoute) => {
        return productListRoutesArray.findIndex((element) => element.route === commingRoute);
    }

    const getTitle: getTitleFunctionType = (commingRoute) => {
        return productListRoutesArray.find((element) => element.route === commingRoute).title;
    }

    const dispatchIt: dispatchItFunctionType = (commingRoute) => {
        if (productListRoutesArray.find((element) => element.route === commingRoute)) {
            dispatch(getCollectionAction(commingRoute, getRouteIndex(commingRoute)));
        }
    }
    return {
        productListRoutesArray: productListRoutesArray,
        getRouteIndex: getRouteIndex,
        getTitle: getTitle,
        dispatchIt: dispatchIt,
    }
    
}

export default useProductListPageRoute;