import { createSlice } from "@reduxjs/toolkit"

type initialstateType = {
    title: string,
    body: string,
    status : {
        loading : boolean,
        error : boolean,
        errorMessage : string,
        errorType : string,
    }
}

var initialstate: initialstateType = {
    title: "",
    body : "",
    status : {
        error : false,
        errorMessage : "",
        errorType : "",
        loading : false
    }
}

const faqSlice = createSlice({
    name: "faq",
    initialState: initialstate,
    reducers: {
        setFAQTitle: (state, action) => {
            state.title = action.payload;
        },
        setFAQBody: (state, action) => {
            state.body = action.payload;
        },
        setLoadingStatus : (state, action) => {
            state.status.loading = action.payload
        }
    }
})

export const faqSliceReducer = faqSlice.reducer;
export const faqSliceAction = faqSlice.actions;