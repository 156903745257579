import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import defImg from '../../../common/assets/images/OrderDetailImg.png';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { allOrdersSagaAction, createContractSagaAction } from '../../../redux/saga/my-account/saga-actions';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import './OrderHistory.scss'
import convertToTwoDecimalValue from '../../../common/utils/convertToTwoDecimalValue';

const OrderHistory: React.FC<{}> = () => {
    const { allOrders, contracts, status } = useAppSelector((state) => state.my_account);
    const { orderHistoryLoading } = status;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParems] = useSearchParams();
    const orderIdByParam: string | null | undefined = searchParems.get("orderID");
    console.log("searchedParems -- order-history -- orderID", searchParems.get("orderID"));

    if(searchParems.get("orderID")){
        localStorage.removeItem("cartId")
        localStorage.removeItem("cartItem-token")
        localStorage.removeItem("checkout-redirect-url")
    }

    React.useEffect(() => {
        if (orderIdByParam) {   // for calling create-contract-api after redirection from shopify!!
            dispatch(createContractSagaAction(orderIdByParam));
        }
    }, [orderIdByParam]) // willUpdate on getting params!

    React.useEffect(() => {
        dispatch(allOrdersSagaAction());
    }, []);

    console.log("allOrders", allOrders)
    const orderEntries: {
        "id": string,
        "name": string,
        "orderNumber": number,
        "processedAt": string,
        "lineItems": {
            "nodes": {
                "variant": {
                    "image": {
                        "url": string | null
                    }
                }
            }[],
        },
        "totalPrice"?: {
            "amount": string
        }
    }[] = React.useMemo(() => {
        return allOrders?.orders?.edges.map((element, index) => {
            return element.node;
        }).reverse();
    }, [allOrders])

    const onDetailsBtnClick = (orderId: string) => {
        navigate('/order-details', {
            state: {
                orderId: orderId
            }
        })
    }
    return (
        <div className="order-history-container">
            { orderHistoryLoading ? 
                <div className="loading"><CircularProgress color="inherit" thickness={4} /></div>
            :
            <>
                {
                orderEntries?.length === 0 ?
                <div className="no-orders">{process.env.REACT_APP_NO_ORDERS}</div>
                :
                <>
                
                    {orderEntries?.map((element, index) => {
                        return (
                            <div className="order-box">
                                <div className="image-wrapper">
                                    <img src={process.env.REACT_APP_ORDER_AND_SERVICE_IMAGE} />
                                    {/* <img src={element?.lineItems?.nodes[0]?.variant?.image?.url} /> */}
                                </div>
                                <div className="text-wrapper">
                                    <div className="order-num">{`Order ${element?.name}`}</div>
                                    <div className="details">
                                        <div className="detail-wrapper">
                                            <div className="detail-head">Total</div>
                                            <div className="detail-body">{`$${convertToTwoDecimalValue(element?.totalPrice?.amount)}`}</div>
                                        </div>
                                        <div className="detail-wrapper">
                                            <div className="detail-head">Order Date</div>
                                            <div className="detail-body">{`${moment(element?.processedAt).format('DD-MM-YYYY')}`}</div>
                                        </div>
                                        {/* <div className="detail-wrapper">
                                            <div className="detail-head">Order Delivered</div>
                                            <div className="detail-body">10 January 2022</div>
                                        </div> */}
                                    </div>
                                </div>
                                <button className="details-btn" onClick={() => { onDetailsBtnClick(element?.id) }}>Order Details</button>
                            </div>
                        )
                    })}
                </>
                }
            </>
        }
        </div>
    )
}

export default OrderHistory;