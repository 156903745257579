export const faqSagaActionType = {
    callGetFAQSaga : 'callGetFAQSaga',
}

export const getFAQ : () => {
    type: string,
} = () => {
    return {
        type : faqSagaActionType.callGetFAQSaga,
    }
}