

export const homeSagaActionType = {
    callGetFeaturedProductSaga : 'callGetFeaturedProductSaga',
    callPromotionalBannerFetchSaga : "callPromotionalBannerFetchSaga",
}
// contractFindAllSaga
export const getFeaturedProductSagaAction : () => {
    type: string,
} = () => {
    return {
        type : homeSagaActionType.callGetFeaturedProductSaga,
    }
}

export const getPromotionalBanners : () => {
    type: string,
} = () => {
    return {
        type : homeSagaActionType.callPromotionalBannerFetchSaga,
    }
}
