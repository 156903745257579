import React from 'react'
import { Divider, Grid, Skeleton } from "@mui/material"

const SkeletonCart = () => {
    return (
        <div>
            <div className="cart-header">
                <Skeleton style={{
                    height: "64px",
                    width: "400px",
                    borderRadius: "10px",
                }} variant='rectangular' />
            </div>
            <Grid container columns={14}>
                <Grid item md={9} className="view-left">
                    <Grid container columns={14} className="cart-box">
                        <Skeleton style={{
                            height: "200px",
                            width: "100%",
                            borderRadius: "10px",
                        }} variant='rectangular' />
                    </Grid>
                </Grid>
                <Grid item md={5} className="view-right">
                    <Skeleton style={{
                        height: "300px",
                        width: "300px",
                        borderRadius: "10px",
                        marginTop: "28px",
                        marginLeft: "64px",
                        marginBottom: "64px"
                    }} variant='rectangular' />
                </Grid>
            </Grid>
        </div>
    )
}

export default SkeletonCart
