import React from 'react';
import Slider, {Settings} from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './ImageCarousel.scss';
import backgroungImage from '../../../assets/images/Rectangle_26.png';

const ImageCarousel = ({ articles }: any) => {

  const settings : Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    nextArrow: <ArrowForwardIosIcon className='slick-next' />,
    prevArrow: <ArrowBackIosNewIcon className='slick-prev' />,
    
  };

  return (
    <div className="ImageCarousel-container">
      <Slider {...settings}>
        {articles.map((article: any, index: number) => (
          <Box className="grid-box">
            <Grid container>
              <Grid item md={7}>
                <div className="text-div">
                  <div className="title">{article.title}</div>
                  <div className="content">
                    {article.content}
                  </div>
                </div>

              </Grid>
              <Grid item md={5}>
                <div key={index} className="img-div" >

                  <img src={article.image?.url} alt={article.title} />
                </div>

              </Grid>
            </Grid>
          </Box>
        ))}
      </Slider>
    </div>
  );
}

export default ImageCarousel;