import React from 'react'
import { Popover, Typography } from '@mui/material'

let handlePopoverOpen: any = null;
let handlePopoverClose: () => void ;
let openPopover: any = undefined;

const UiPopover = ({ anchorElForPopover, setAnchorElForPopover }: any) => {

    handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElForPopover(event.currentTarget);
    };

    handlePopoverClose = () => {
        setAnchorElForPopover(null);
    };

    openPopover = Boolean(anchorElForPopover);

    return (
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={openPopover}
            anchorEl={anchorElForPopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Typography sx={{ p: 1, minWidth: '8.5rem', fontSize: '12px' }}>Feature coming soon</Typography>
        </Popover>
    )
}

export { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover };

/*
    import { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover } from '../CustomPopover';

    Add state:
        const [anchorElForPopover, setAnchorElForPopover] = React.useState<HTMLElement | null>(null);

    Add following attributes to element:
        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}

    Add component:
        <UiPopover anchorElForPopover={anchorElForPopover} setAnchorElForPopover={setAnchorElForPopover}/>
 */