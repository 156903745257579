import React, { useState } from "react";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuItem,
    MenuList,
    useMediaQuery,
    useTheme,
    Badge,
    Popover,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Logo from "../../assets/images/hid_logo.png"
import DrawerComponent from "../Drawer/DrawerComponent";
import imgSrc from '../../assets/images/img_avatar.png'
import { useNavigate } from "react-router-dom";
import './Header.scss';
import { useAppDispatch, useAppSelector } from "../../../redux";
import { authSliceAction } from "../../../redux/slices/auth";
import { handlePopoverOpen, handlePopoverClose, openPopover, UiPopover } from '../CustomPopover';
import useProductListPageRoute from "../../../hooks/useProductListPageRoute";
import useLogoutHandler from "../../../hooks/useLogoutHandler"

const Header = () => {
    const [triggerLogout] = useLogoutHandler();
    const { cartSize } = useAppSelector((state) => state.cart);
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {productListRoutesArray} = useProductListPageRoute(); // the useProductListPageRoute is called here!
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const anchorRef = React.useRef<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [anchorElForPopover, setAnchorElForPopover] = React.useState<HTMLElement | null>(null);


    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAvatar = () => {
        setAnchorEl(null);
    };

    const openAvatar = Boolean(anchorEl);
    const id = openAvatar ? 'simple-popover' : undefined;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpen(false);
    };

    const handleMenuItem = (event: Event | React.SyntheticEvent, sub_route_name: string) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        console.log("jai-shree-ram", sub_route_name)
        navigate(`/products/${sub_route_name}`);

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <div className="header-container">
            <UiPopover anchorElForPopover={anchorElForPopover} setAnchorElForPopover={setAnchorElForPopover}/>
            <AppBar sx={{ background: "white", color: "black", boxShadow: 'none', borderBottom: '1px solid #D7D7D7' }}>
                <div className="header-top" >

                    <div className="mail-support"
                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}>
                        <EmailOutlinedIcon sx={{ transform: "scale(0.6)" }} /> {process.env.REACT_APP_SUPPORT_EMAIL}
                    </div>
                    <div className="enquire"
                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}>
                        <HelpOutlineRoundedIcon sx={{ transform: "scale(0.6)" }} /> Enquire</div>
                    <div className="image-wrapper" aria-describedby={id} onClick={handleClick}>
                        <img src={imgSrc} alt="profile" className="profile-image" />
                        {/* onClick={() => dispatch(authSliceAction.logOut())} */}
                    </div>
                    <Popover
                        id={id}
                        open={openAvatar}
                        anchorEl={anchorEl}
                        onClose={handleCloseAvatar}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        className="avatar-popover"
                    >

                        <Typography sx={{ p: 1 }}>
                            <NavLink to="/my-account" onClick={handleCloseAvatar}>
                                My Account
                            </NavLink>
                        </Typography>
                        <Typography sx={{ p: 1 }} onClick={() => {
                            //dispatch(authSliceAction.logOut());
                            triggerLogout();
                            handleCloseAvatar(); }}>
                            Logout
                        </Typography>
                    </Popover>

                </div>
                <Toolbar sx={{ height: "4rem" }}>
                    <div className="logo-wrapper" onClick={() => navigate('/')} >
                        <img src={Logo} alt="" className="logo-contant" />
                    </div>
                    {isMatch ? (
                        <>
                            <div className="cart-wrapper" style={{ paddingRight: "2rem" }}>
                                <Badge color="primary" badgeContent={0}>
                                    <ShoppingCartOutlinedIcon
                                        sx={{ transform: "scale(1.3)", marginLeft: "auto" }}
                                        className="cart-icon"
                                        onClick={() => navigate('/cart')} />
                                </Badge>
                            </div>
                            {/* <DrawerComponent /> */}
                        </>
                    ) : (
                        <Box
                            justifyContent="space-between"
                            sx={{ flexGrow: 1, display: { md: 'flex' } }}>

                            <div className="header-menu">

                                <Typography textAlign="center" className="page-link" style={{ paddingLeft: "42px" }}>
                                    <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        Home
                                    </NavLink>
                                </Typography>

                                <Typography textAlign="center" className="page-link-product">
                                    {/* <NavLink to="" className={({ isActive }) => (isActive ? 'active' : 'inactive')}> */}
                                    <div
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}>About</div>
                                    {/* </NavLink> */}
                                </Typography>

                                <Typography textAlign="center" className="page-link">
                                    <NavLink to="my-account" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        My Account
                                    </NavLink>
                                </Typography>

                                <Typography textAlign="center" onClick={handleToggle} className="page-link-product" style={{ paddingRight: "0" }} ref={anchorRef} >
                                    {/* <NavLink unselectable="on" to={location.pathname} onClick={handleToggle} className={({ isActive }) => (isActive || location.pathname.includes('/products/') ? 'active' : 'inactive')} > */}
                                    <div className={location.pathname.includes('/products/') ? 'active' : 'inactive'}>
                                        Products
                                    </div>
                                    {/* </NavLink> */}
                                </Typography>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <button className="toggle-btn"

                                        onClick={handleToggle}
                                    >
                                        {open ? <ExpandLess /> : <ExpandMore />}
                                    </button>
                                    <Popper
                                        open={open}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        placement="bottom-start"
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList
                                                            autoFocusItem={open}
                                                            id="composition-menu"
                                                            aria-labelledby="composition-button"
                                                            onKeyDown={handleListKeyDown}
                                                        >
                                                            {productListRoutesArray.map((element : {title : string, route : string}) => {
                                                                return (<MenuItem onClick={(event) => {
                                                                    handleMenuItem(event, element.route)
                                                                }}>
                                                                    <Typography textAlign="center" component="a" href="#">
                                                                        {element.title}
                                                                    </Typography>
                                                                </MenuItem>)
                                                            })}
                                                            {/* <MenuItem onClick={(event) => {
                                                            handleMenuItem(event, "hid-origo-api")
                                                        }}>
                                                            <Typography textAlign="center" component="a" href="#">
                                                                HID Origo API
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem onClick={(event) => {
                                                            handleMenuItem(event, "hid-origo-sdk")
                                                        }}>
                                                            <Typography textAlign="center" component="a" href="#">
                                                                HID Origo SDK
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem onClick={(event) => {
                                                            handleMenuItem(event, "aero")
                                                        }}>
                                                            <Typography textAlign="center" component="a" href="#">
                                                                Aero
                                                            </Typography>
                                                        </MenuItem> */}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                </div>
                                <Typography textAlign="center" className="page-link-product">
                                    {/* <NavLink to="" className={({ isActive }) => (isActive ? 'active' : 'inactive')}> */}
                                    <div
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}>Application</div>
                                    {/* </NavLink> */}
                                </Typography>

                                <Typography textAlign="center" className="page-link-product">
                                    {/* <NavLink to="" className={({ isActive }) => (isActive ? 'active' : 'inactive')}> */}
                                    <div
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}>Support</div>
                                    {/* </NavLink> */}
                                </Typography>
                            </div>

                            <div className="cart-wrapper" >
                                <Badge color="error" badgeContent={cartSize} onClick={() => navigate('/cart')} >
                                    <ShoppingCartOutlinedIcon sx={{ transform: "scale(1.3)" }} className="cart-icon" />
                                </Badge>
                            </div>

                        </Box>
                    )}
                </Toolbar>
            </AppBar>         
        </div>
    );
};

export default Header;