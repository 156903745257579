import React from 'react'
import { Breadcrumbs, Grid, Skeleton, Stack } from "@mui/material"
import Iconography from '@hid-galaxy-ui/galaxy-react/components/Iconography'

const SkeletonOrderDetails = () => {
    return (
        <div>
            <Stack spacing={2} className="nav-info">
                <Breadcrumbs separator={<Iconography icon="angleRight" />} >
                    {[
                        <h6>My Account</h6>,
                        <h6>Order History</h6>,
                        <h5></h5>
                    ]}
                </Breadcrumbs>
            </Stack>
            <>
                <Grid container columns={12} className="header-invoice">
                    <Grid item md={8} className="header-box">
                        <Skeleton style={{
                            height: "60px",
                            width: "80%",
                            borderRadius: "10px",
                            marginLeft: "4rem",
                        }} variant='rectangular' />
                    </Grid>
                    <Grid item md={4} className="invoice-box">
                        <Skeleton style={{
                            height: "30px",
                            width: "80%",
                            borderRadius: "10px",
                            marginTop: "1rem",
                            marginLeft: "5rem",
                        }} variant='rectangular' />
                    </Grid>
                </Grid>

                <Grid container columns={14} className="container-box">
                    <Grid item lg={8} md={13} className="left">
                        <Grid className="head-box1">
                            <Skeleton style={{
                                height: "50px",
                                width: "80%",
                                borderRadius: "10px",
                                marginTop: "2rem",
                            }} variant='rectangular' />
                        </Grid>

                        <Grid>
                            <Skeleton style={{
                                height: "468px",
                                width: "100%",
                                borderRadius: "10px",
                                marginTop: "2rem",
                            }} variant='rectangular' />
                        </Grid>
                    </Grid>

                    <Grid item lg={6} md={14} className="right">
                        <Grid className="head-box2">
                            <Skeleton style={{
                                height: "50px",
                                width: "80%",
                                borderRadius: "10px",
                                marginTop: "2rem",
                            }} variant='rectangular' />
                        </Grid>

                        <Grid>
                            <Skeleton style={{
                                height: "335px",
                                width: "88%",
                                borderRadius: "10px",
                                marginTop: "2rem",
                            }} variant='rectangular' />
                        </Grid>

                        <Grid>
                            <Skeleton style={{
                                height: "90px",
                                width: "88%",
                                borderRadius: "10px",
                                marginTop: "2rem",
                            }} variant='rectangular' />
                        </Grid>
                    </Grid>
                </Grid>
            </>

        </div>
    )
}

export default SkeletonOrderDetails
