import {createSlice} from "@reduxjs/toolkit"


type initialstateType = {
    cartItems : {
        "id"?: string | null,
        "checkoutUrl"?: string | null,
        "lines"?: {
            "edges": {
                    "node": {
                        "id": string | null,
                        "quantity": number | null,
                        "attributes": {
                                "key": string | null,
                                "value": string | null
                            }[] | [],
                        "merchandise": {
                            "id": string | null,
                            "price": {
                                "amount": string | null
                            },
                            "product": {
                                "id": string | null,
                                "title": string | null,
                                "productType": string | null,
                                "metafield": {
                                    "value": string | null
                                },
                                "featuredImage": {
                                    "url": string | null
                                }
                            }
                        }
                    }
                }[]
        },
        "cost"?: {
            "totalAmount": {
                "amount": string | null
            },
            "subtotalAmount": {
                "amount": string | null
            }
        },
        "buyerIdentity"?: {
            "customer": {
                "id": string | null,
                "email": string | null,
                "phone": string | null
            }
        }
    },
    cartSize : number,
    isLoading : boolean,
    checkoutURL : string,
    errorInfo : {
        severity : string,
        errorMsg : string,
        open : boolean
    }
}

var initialstate : initialstateType = {
    cartItems : {},
    cartSize : 0,
    isLoading : false,
    checkoutURL : "",
    errorInfo : {
        severity : "",
        errorMsg : "",
        open : false
    }
}

const cartSlice = createSlice({
    name : "cart",
    initialState : initialstate,
    reducers : {
        setCartItems : (state, action) => {
            state.cartItems = action.payload; 
            // state.cartSize = state.cartItems?.lines?.edges?.length;
            state.cartSize = state.cartItems?.lines?.edges?.filter((element) => element?.node?.merchandise?.product?.productType === 'Services').length;
        },
        setIsLoading : (state, action) => {
            state.isLoading = action.payload
        },
        setCheckoutURL : (state, action) => {
            state.checkoutURL = action.payload;
        },
        errorInfoTrigger : (state, action) => {
           state.errorInfo = action.payload
        },
        reset : (state) => {
            state.cartItems = {}
            state.cartSize = 0
        }
    }
})

export const cartSliceReducer = cartSlice.reducer;
export const cartSliceAction = cartSlice.actions;