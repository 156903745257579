import { call, put, takeLatest, delay, select } from "redux-saga/effects"
import { axiosGraphqlInstance, axiosRestInstance } from "../../../common/axios"
import { faqSagaActionType} from './saga-actions'
import { faqSliceAction} from './../../slices/faq'
import { AxiosResponse } from "axios"

const faqFetchAPI = async () => {
    let response = axiosGraphqlInstance.post<AxiosResponse<{
        page: {
            title: string,
            body: string
        }

    }>>('/graphql.json', {
        query: `{
            page(handle:"faq"){
              title
              body
            }
          }`,
        variables: {

        }
    }).then((response) => response.data.data.page)
    return response;
}

function* faqFetchSaga(actions: {
    type: string
}) {
    try {
        yield put(faqSliceAction.setLoadingStatus(true));
        let response: { body: string, title: string} = yield call(faqFetchAPI);
        yield put(faqSliceAction.setFAQBody(response.body));
        yield put(faqSliceAction.setFAQTitle(response.title));
        console.log("response --> faq", response.body)
        yield put(faqSliceAction.setLoadingStatus(false));
    } catch (e) {
        yield put(faqSliceAction.setLoadingStatus(false));
        console.log(e)
    }
}

function* faqSaga() {
    yield takeLatest(faqSagaActionType.callGetFAQSaga, faqFetchSaga);
}

export default faqSaga;