import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from "redux-saga"
import rootSaga from "./saga/index"
import {homeScileReducer} from "./slices/home"
import {productListScileReducer} from "./slices/product-list"
import {productDetailScileReducer} from "./slices/product-detail"
import {myAccountScileReducer} from "./slices/my-account"
import { authReducer } from './slices/auth';
import {cartSliceReducer } from './slices/cart'
import {faqSliceReducer} from './slices/faq'
// import {cartReducer } from './slices/cartItems'
// import { cartItemsReducer } from './slices/showAllCartItems';
// import { deleteCartItemReducer} from './slices/deleteCartItem';

let sagaMiddleware = createSagaMiddleware();

const rootReducer =  combineReducers({
        auth: authReducer,
        home: homeScileReducer,
        plp: productListScileReducer,
        cart: cartSliceReducer,
        pdp: productDetailScileReducer,
        my_account : myAccountScileReducer,
        faq: faqSliceReducer
        //cart : cartReducer,
        //deleteCartItem : deleteCartItemReducer,
        //cartItems: cartItemsReducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>  {
            return getDefaultMiddleware({
                thunk : false,
                serializableCheck: false,
        }).concat(sagaMiddleware);
    },
  })

  sagaMiddleware.run(rootSaga)
  export type RootStateType = ReturnType<typeof rootReducer>
  export const useAppDispatch : () => typeof store.dispatch = useDispatch;
  export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;