import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux';
import { contractFindAllSagaAction } from '../../../redux/saga/my-account/saga-actions';
import Child from './child'
import { CircularProgress } from '@mui/material';
import './ServiceContracts.scss'

const ServiceContracts = () => {
	const dispatch = useAppDispatch();
	const { contracts, status } = useAppSelector((state) => state.my_account);
	const { contractsLoading } = status;
	React.useEffect(() => {
		if (contracts.length === 0) {
			dispatch(contractFindAllSagaAction());
		}
	}, []);

	console.log("contracts", contracts);
	return (
		<div className="service-contracts-container">
			{ contractsLoading ?
				<div className="loading"><CircularProgress color="inherit" thickness={4} /></div>
			:
			<>
				{
				contracts?.length === 0 ?
				<div className="no-service-contracts">{process.env.REACT_APP_NO_SERVICE_CONTRACTS}</div>
				:
				<>
					{contracts.map((element, index) => {
						return (<Child cardDetails={element}/>)
					})}
					{/* {[
						{
							Active: "Y",
							ContractEndDate: "2024-04-10T11:50:38.000Z",
							ContractId: "5344730448181",
							ContractName: "API validation",
							ContractStartDate: "2023-04-11T11:50:38.000Z",
							Created: "2023-04-11T11:53:25.000Z",
							LastUpdated: "2023-04-11T11:53:25.000Z",
							OnboardingStageId: "HID001",
							PartnerId: "maryagnel.sharon@infosys.com",
							PartnerManagerId: "neilkiran_vulchi@infosys.com",
							Status: "Processing",
						},
						{
							Active: "Y",
							ContractEndDate: "2024-04-10T11:50:38.000Z",
							ContractId: "5344730448181",
							ContractName: "API validation",
							ContractStartDate: "2023-04-11T11:50:38.000Z",
							Created: "2023-04-11T11:53:25.000Z",
							LastUpdated: "2023-04-11T11:53:25.000Z",
							OnboardingStageId: "HID001",
							PartnerId: "maryagnel.sharon@infosys.com",
							PartnerManagerId: "neilkiran_vulchi@infosys.com",
							Status: "Expired",
						},
					].map((element, index) => {
						return (<Child cardDetails={element} />)
					})} */}
				</>
				}
			</>
			}
		</div>
	)
}

export default ServiceContracts;
